/**
 * @file It contains vuex groups module mutations to mutate the state variables.
 */
export default {
  setExports: (state, exports) => {
    state.exports = exports
  },
  setAddingExport: (state, addingExport) => {
    state.addingExport = addingExport
  },
  setUpdatingExport: (state, updatingExport) => {
    state.updatingExport = updatingExport
  },
  setExportUpdated: (state, exportUpdated) => {
    state.exportUpdated = exportUpdated
  },
  updateExports: (state, exports) => {
    for (const item of exports) {
      const index = state.exports.findIndex(stateExports => stateExports?.id === item.id)
      if (index >= 0) {
        state.exports.splice(index, 1, { ...state.exports[index], ...item })
      } else {
        state.exports.push(item)
      }
    }
  }
}