const fontFamily = "Nunito"

const style = {
  fontFamily
}

const colors = ["#003026", "#006646", "#00B687", "#B8D0EB", "#B95F89", "#E49D9D"]

// Function to convert hex color to RGB
const hexToRgb = hex => {
  const red = parseInt(hex.slice(1, 3), 16),
        green = parseInt(hex.slice(3, 5), 16),
        blue = parseInt(hex.slice(5, 7), 16)

  return [red, green, blue]
}

// Function to convert RGB color to hex
const rgbToHex = (red, green, blue) => {
  return "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)
}

// Function to generate color variants
const generateColorVariants = (baseColors, numberOfColorVariants) => {
  const iterations = Math.ceil(numberOfColorVariants / (baseColors.length * 2))

  const lighterVariants = []
  const darkerVariants  = []

  for (let iterator = 1; iterator <= iterations; iterator++) {
    const variation = iterator * (Math.floor(Math.random() * 10) + 1)
    baseColors.forEach(color => {
      const [red, green, blue] = hexToRgb(color)

      const lighter = rgbToHex(
        Math.min(red + variation, 255),
        Math.min(green + variation, 255),
        Math.min(blue + variation, 255)
      )

      const darker = rgbToHex(
        Math.max(red - variation, 0),
        Math.max(green - variation, 0),
        Math.max(blue - variation, 0)
      )

      lighterVariants.push(lighter)
      darkerVariants.push(darker)
    })
  }
  return [
    ...baseColors,
    ...lighterVariants,
    ...darkerVariants
  ]
}

export const getBarChartOptions = (categories, numberOfColorVariants = 0, horizontal = false, stacked = true) => ({
  colors     : generateColorVariants(colors, numberOfColorVariants),
  plotOptions: {
    bar: {
      horizontal,
      borderRadius: 4
    }
  },
  chart: {
    legend : "show",
    stacked,
    toolbar: {
      tools: {
        download: false
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories,
    labels: {
      style
    }
  },
  yaxis: {
    labels: {
      style
    }
  },
  legend: {
    fontFamily,
    position       : "bottom",
    height         : 50,
    horizontalAlign: "left",
    itemMargin     : {
      horizontal: 15
    }
  },
  tooltip: {
    style
  }
})

export const getHorizontalBarChartHeight = xAxisItemsLength =>
  xAxisItemsLength < 5 ? 250 : xAxisItemsLength * window.innerWidth / 40

export const getLineChartOptions = (categories, numberOfColorVariants = 0, dashArray = null, compareToPreviousData) => {
  let colorsForLineChart = generateColorVariants(colors, numberOfColorVariants)
  if (compareToPreviousData) {
    colorsForLineChart = colorsForLineChart.slice(0, numberOfColorVariants)
    colorsForLineChart = colorsForLineChart.concat(colorsForLineChart)
  }
  return {
    colors: colorsForLineChart,
    chart : {
      legend : "show",
      toolbar: {
        tools: {
          download: false
        }
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories,
      labels: {
        style
      }
    },
    yaxis: {
      labels: {
        style
      }
    },
    legend: {
      fontFamily,
      position       : "bottom",
      height         : 50,
      horizontalAlign: "left",
      itemMargin     : {
        horizontal: 15
      }
    },
    tooltip: {
      style
    },
    stroke: {
      width: 2,
      dashArray
    }
  }
}

export const getDoughnutChartOptions = (categories, numberOfColorVariants = 0) => ({

  colors: generateColorVariants(colors, numberOfColorVariants),
  chart : {
    legend : "show",
    type   : "donut",
    toolbar: {
      tools: {
        download: false
      }
    }
  },
  labels    : categories,
  dataLabels: {
    enabled: false
  },
  legend: {
    fontFamily,
    position: "right"
  },
  tooltip: {
    style
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show : true,
          total: {
            show      : true,
            showAlways: true,
            label     : "Total",
            fontFamily,
            formatter : function(total) {
              return total.globals.seriesTotals.reduce((sum, value) => {
                return sum + value
              }, 0)
            }
          }
        }
      }
    }
  }
})