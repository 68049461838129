import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, TRANSLATION_UPLOAD_STATUS } from "@/constants"
import { getBaseURL } from "@/utils"
import { generateMD5ForFile, downloadFile, getFileData } from "@/utils"

const path = "/v1/channels"

export default {
  /**
   * This action will load channels.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load channels.
   */
  async loadChannels(context, payload) {
    if (payload?.uploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED) {
      delete payload.uploadStatus
      context.commit("setLoadingChannels", false)
    } else {
      context.commit("setLoadingChannels", true)
    }

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getChannelsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getChannelsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateChannels", getChannelsResponse.data)
      } else {
        context.commit("setChannels", getChannelsResponse.data)
      }
    } else {
      // something went wrong while getting channels
    }

    context.commit("setLoadingChannels", false)
  },

  /**
   * This action uploads triage translation file to S3 bucket.
   * @param {*} context is the store.
   * @param {*} payload contains field and file to be uploaded.
   */
  async uploadTriageTranslationFile(context, payload) {
    context.commit("setUploadingTriageTranslation", true)
    context.commit("setTriageTranslationFileUploaded", false)
    const { channel, file }                = payload
    const httpClient                       = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const md5                              = await generateMD5ForFile(file)
    const uploadTranslationFileUrlResponse = await httpClient.post(`/${channel.id}/triage-transl-ul-url`, {
      md5
    })
    if(uploadTranslationFileUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadTranslationFileUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadTranslationFileUrlResponse.data.fields[key]))
      form.append("file", file)
      const headers                = {
        "Content-MD5": Buffer.from(md5, "hex").toString("base64")
      }
      const uploadFileToS3Response = await fetch(uploadTranslationFileUrlResponse.data.url, {
        headers,
        method: "POST",
        body  : form
      })
      if(uploadFileToS3Response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        await httpClient.put(`/${channel.id}`, {
          triageTranslationUploadStatus: TRANSLATION_UPLOAD_STATUS.INITIATED
        })
        context.commit("updateChannels", [{
          ...channel,
          ...{ "triageTranslationUploadStatus": TRANSLATION_UPLOAD_STATUS.INITIATED }
        }])
      }
    }
    context.commit("setUploadingTriageTranslation", false)
    context.commit("setTriageTranslationFileUploaded", true)
  },

  /**
   * This action will load forms of a channel.
   * @param {*} context is the store.
   */
  async loadForms(context, id) {
    context.commit("setLoadingForms", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFormsResponse = await httpClient.get(`/${id}/forms`)

    if (getFormsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormsForChannel", {
        id   : id,
        forms: getFormsResponse.data
      })
    } else {
      // something went wrong while getting forms
    }

    context.commit("setLoadingForms", false)
  },

  /**
   * This action will add form to a channel.
   * @param {*} context is the store.
   */
  async addChannelForm(context, payload) {
    context.commit("setAddingChannelForm", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postChannelFormsResponse = await httpClient.post(`/${payload.id}/forms/${payload.form}`)

    if (postChannelFormsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("pushFormsForChannel", {
        id  : payload.id,
        form: payload.form
      })
    }

    context.commit("setAddingChannelForm", false)
  },

  /**
   * This action removes a particular channel form.
   * @param {*} context is the store.
   * @param {*} payload contains id of channel and form id.
   */
  async removeChannelForm(context, payload) {
    context.commit("setRemovingChannelForm", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteChannelFormResponse = await httpClient.delete(`/${payload.id}/forms/${payload.form}`)

    if (deleteChannelFormResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeFormForChannel", {
        id  : payload.id,
        form: payload.forms
      })
    } else {
      // something went wrong while removing channel form
    }

    context.commit("setRemovingChannelForm", true)
  },

  /**
   * This action will add a channel.
   * @param {*} context is the store.
   */
  async addChannel(context, payload) {
    context.commit("setAddingChannel", true)
    context.commit("setChannelAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postChannelResponse = await httpClient.post(undefined, payload)

    if (postChannelResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateChannels", [postChannelResponse.data])
      context.commit("setChannelAdded", true)
    } else if (postChannelResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setChannelAddError", postChannelResponse.data)
    } else {
      // something went wrong while adding a channel
    }

    context.commit("setAddingChannel", false)
  },

  /**
   * This action will load a particular channel.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the channel to be loaded
   */
  async loadChannel(context, payload) {
    context.commit("setLoadingChannels", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getChannelResponse = await httpClient.get(`/${payload}`)

    if (getChannelResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateChannels", [getChannelResponse.data])
    } else {
      // something went wrong while getting an channel
    }

    context.commit("setLoadingChannels", false)
  },

  /**
   * This action updates summary of a channel.
   * @param {*} context is the store.
   * @param {*} channel id and summary of a channel.
   */
  async updateChannel(context, channel) {
    const id = channel.id
    delete channel.id
    const propertiesToBeUpdated = Object.keys(channel)
    for (const property of propertiesToBeUpdated) {
      if (channel[property] === undefined) {
        channel[property] = null
      }
    }
    context.commit("setUpdatingChannel", propertiesToBeUpdated)
    context.commit("resetChannelUpdated", propertiesToBeUpdated)
    context.commit("resetChannelUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateChannelResponse = await httpClient.put(`/${id}`, channel)

    if (updateChannelResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      const channelToUpdate = {
        id,
        ...channel
      }

      if (channel.draftPrivacyPolicyText || channel.publishPrivacyPolicy) {
        await context.dispatch("loadChannel", id)
      }
      if ("privacyPolicy" in channel && !channel.privacyPolicy) {
        context.commit("setPrivacyPolicyTranslations", new Object())
        context.commit("setDraftPrivacyPolicyTranslations", new Object())
        await context.dispatch("loadChannel", id)
      }

      context.commit("updateChannels", [channelToUpdate])
      context.commit("setChannelUpdated", propertiesToBeUpdated)
    } else if (updateChannelResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setChannelUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateChannelResponse.data
      })
    }

    context.commit("resetUpdatingChannel", propertiesToBeUpdated)
  },

  /**
   * This action will load translation configurations of a channel.
   * @param {*} context is the store.
   */
  async loadTranslationConfigurations(context, id) {
    context.commit("setLoadingTranslationConfigurations", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getTranslationConfigurationsResponse = await httpClient.get(`/${id}/translation-configurations`)

    if (getTranslationConfigurationsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setTranslationConfigurationsForChannel", {
        id,
        translationConfigurations: getTranslationConfigurationsResponse.data
      })
    } else {
      // something went wrong while getting translation configuration
    }

    context.commit("setLoadingTranslationConfigurations", false)
  },

  async updateTranslationConfigurations(context, payload) {
    context.commit("setUpdatingTranslationConfigurations", true)
    context.commit("setTranslationConfigurationsUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const putChannelTranslationConfigurationsResponse = await httpClient.put(
      `/${payload.id}/translation-configurations`,
      payload.translationConfigurations
    )

    if (putChannelTranslationConfigurationsResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setTranslationConfigurationsForChannel", {
        id                       : payload.id,
        translationConfigurations: payload.translationConfigurations
      })
      context.commit("setTranslationConfigurationsUpdated", true)
    }

    context.commit("setUpdatingTranslationConfigurations", false)
  },

  /**
   * This action uploads the channel logo.
   * @param {*} context is the store.
   * @param {*} payload is data to be processed.
   */
  async addChannelLogo(context, payload) {
    context.commit("setAddingChannelLogo", true)
    context.commit("setChannelLogoAdded", false)
    const file      = payload.file
    const md5Result = await generateMD5ForFile(file)
    const logo      = {
      name: file.name,
      size: file.size,
      md5 : md5Result
    }

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const preSignedUrlResponse = await httpClient.post(`/${payload.id}/logo`, logo)

    if (preSignedUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(preSignedUrlResponse.data.fields).forEach(key =>
        form.append(key, preSignedUrlResponse.data.fields[key]))
      form.append("file", file)

      const headers = {
        "Content-MD5": Buffer.from(md5Result, "hex").toString("base64")
      }

      const response = await fetch(preSignedUrlResponse.data.url, { method: "POST", body: form, headers })

      if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.commit("setChannelLogoAdded", true)
        context.commit("updateChannels", [{
          id  : payload.id,
          logo: logo
        }])
      } else {
        // something went wrong while uploading channel logo
      }
    } else {
      // something went wrong while uploading channel logo
    }
    context.commit("setAddingChannelLogo", false)
  },

  /**
   * This action removes channel logo.
   * @param {*} context is the store.
   * @param {*} payload contains id of a channel.
   */
  async removeChannelLogo(context, payload) {
    context.commit("setRemovingChannelLogo", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteChannelLogoResponse = await httpClient.delete(`/${payload}/logo`)

    if (deleteChannelLogoResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setChannelLogoRemoved", true)
      context.commit("updateChannels", [{
        id  : payload,
        logo: null
      }])
    } else {
      // something went wrong while deleting channel logo
    }

    context.commit("setRemovingChannelLogo", false)
  },

  /**
   * This action downloads channels overview xlsx
   * @param {*} context is the store
   */
  async downloadChannelsOverview(context) {
    context.commit("setDownloadingChannelsOverview", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], "/v1/channels-export")
    }, context)

    const channelsExportResponse = await httpClient.get()

    if (channelsExportResponse.status === HTTP_STATUS_CODE.OK) {
      await downloadFile(channelsExportResponse.data, null, "channels-overview.xlsx")
    }

    context.commit("setDownloadingChannelsOverview", false)
  },

  /**
   * This action will load form templates of a channel.
   * @param {*} context is the store.
   */
  async loadChannelFormTemplates(context, id) {
    context.commit("setLoadingChannelFormTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getChannelFormTemplatesResponse = await httpClient.get(`/${id}/form-templates`)

    if (getChannelFormTemplatesResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormTemplatesForChannel", {
        id           : id,
        formTemplates: getChannelFormTemplatesResponse.data
      })
    } else {
      // something went wrong while getting form templates
    }

    context.commit("setLoadingChannelFormTemplates", false)
  },

  /**
   * This action will add form templates to a channel.
   * @param {*} context is the store.
   */
  async addChannelFormTemplates(context, payload) {
    context.commit("setAddingChannelFormTemplates", true)
    context.commit("setChannelFormTemplatesAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postChannelFormTemplateResponse = await httpClient.post(`/${payload.id}/form-templates`, payload.formTemplates)

    if (postChannelFormTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("pushFormTemplatesForChannel", {
        id           : payload.id,
        formTemplates: payload.formTemplates
      })
      context.commit("setChannelFormTemplatesAdded", true)
    }

    context.commit("setAddingChannelFormTemplates", false)
  },

  /**
  * This action removes a particular form template from a channel.
  * @param {*} context is the store.
  * @param {*} param contains channelId and formTemplateId.
  */
  async removeChannelFormTemplate(context, { channelId, formTemplateId }) {
    context.commit("setRemovingChannelFormTemplate", true)
    context.commit("setChannelFormTemplateRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const removeChannelFormTemplateResponse = await httpClient.delete(`/${channelId}/form-templates/${formTemplateId}`)

    if (removeChannelFormTemplateResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeFormTemplateOfChannel", {
        id: channelId,
        formTemplateId
      })
      context.commit("setChannelFormTemplateRemoved", true)
    }

    context.commit("setRemovingChannelFormTemplate", false)
  },

  /**
   * This action downloads file associated with channel description translations.
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the channel.
   */
  async downloadDescriptionTranslations(context, payload) {
    context.commit("setDownloadingDescriptionTranslations", true)

    const { id, name } = payload
    const httpClient   = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getTranslationDownloadURLResponse = await httpClient.get(`/${id}/desc-transl-dl-url`)
    if (getTranslationDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      const { url, md5 } = getTranslationDownloadURLResponse.data
      await downloadFile(url, md5, name)
    }

    context.commit("setDownloadingDescriptionTranslations", false)
  },

  /**
   * This action downloads file associated with channel triage translations.
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the channel.
   */
  async downloadTriageTranslations(context, payload) {
    context.commit("setDownloadingTriageTranslations", true)

    const { id, name } = payload
    const httpClient   = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getTranslationDownloadURLResponse = await httpClient.get(`/${id}/triage-transl-dl-url`)
    if (getTranslationDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      const { url, md5 } = getTranslationDownloadURLResponse.data
      await downloadFile(url, md5, name)
    }

    context.commit("setDownloadingTriageTranslations", false)
  },

  /**
   * This action uploads description translation file to S3 bucket.
   * @param {*} context is the store.
   * @param {*} payload contains field and file to be uploaded.
   */
  async uploadDescriptionTranslationFile(context, payload) {
    context.commit("setUploadingDescriptionTranslation", true)
    context.commit("setDescriptionTranslationFileUploaded", false)

    const { channel, file } = payload

    const httpClient                       = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const md5                              = await generateMD5ForFile(file)
    const uploadTranslationFileUrlResponse = await httpClient.post(`/${channel.id}/desc-transl-ul-url`, {
      md5
    })

    if(uploadTranslationFileUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadTranslationFileUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadTranslationFileUrlResponse.data.fields[key]))
      form.append("file", file)

      const headers = {
        "Content-MD5": Buffer.from(md5, "hex").toString("base64")
      }

      const uploadFileToS3Response = await fetch(uploadTranslationFileUrlResponse.data.url, {
        headers,
        method: "POST",
        body  : form
      })

      if(uploadFileToS3Response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.commit("updateChannels", [{
          ...channel,
          ...{ "descriptionTranslationUploadStatus": TRANSLATION_UPLOAD_STATUS.INITIATED }
        }])

        await httpClient.put(`/${channel.id}`, {
          descriptionTranslationUploadStatus: TRANSLATION_UPLOAD_STATUS.INITIATED
        })
      }
    }

    context.commit("setUploadingDescriptionTranslation", false)
    context.commit("setDescriptionTranslationFileUploaded", true)
  },

  /**
   * This action gets privacy policy translations from the files in s3
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the channel.
   */
  async loadPrivacyPolicyTranslations(context, payload) {
    const { id, draft, actual } = payload
    context.commit("setLoadingPrivacyPolicyTranslations", true)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postPrivacyPolicyTranslationDownloadURLResponse = await httpClient.post(`/${id}/privacy-policy/dl-url`, {
      draft,
      actual
    })

    if (postPrivacyPolicyTranslationDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      const postResponseData               = postPrivacyPolicyTranslationDownloadURLResponse.data
      const privacyPolicyTranslations      = postResponseData.privacyPolicyTranslations
      const draftPrivacyPolicyTranslations = postResponseData.draftPrivacyPolicyTranslations

      if (privacyPolicyTranslations) {
        const { url, md5 }                  = privacyPolicyTranslations
        const privacyPolicyTranslationsData = await getFileData(url, md5)
        context.commit("setPrivacyPolicyTranslations", privacyPolicyTranslationsData)
      }
      if (draftPrivacyPolicyTranslations) {
        const { url, md5 }                       = draftPrivacyPolicyTranslations
        const draftPrivacyPolicyTranslationsData = await getFileData(url, md5)
        context.commit("setDraftPrivacyPolicyTranslations", draftPrivacyPolicyTranslationsData)
      }
    }

    context.commit("setLoadingPrivacyPolicyTranslations", false)
  },

  /**
   * This action uploads description translation file to S3 bucket.
   * @param {*} context is the store.
   * @param {*} payload contains field and file to be uploaded.
   */
  async uploadDraftPrivacyPolicyTranslationFile(context, payload) {
    context.commit("setUploadingDraftPrivacyPolicyTranslations", true)
    context.commit("setDraftPrivacyPolicyTranslationsFileUploaded", false)

    const { id, draftPrivacyPolicyTranslations } = payload

    const fileData = JSON.stringify(draftPrivacyPolicyTranslations)
    const file     = new File([fileData], "channel-draft-privacy-policy-translations.json")

    const httpClient                        = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const md5                               = await generateMD5ForFile(file)
    const uploadTranslationsFileUrlResponse = await httpClient.post(`/${id}/privacy-policy/ul-url`, {
      md5
    })

    if(uploadTranslationsFileUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadTranslationsFileUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadTranslationsFileUrlResponse.data.fields[key]))
      form.append("file", file)

      const headers = {
        "Content-MD5": Buffer.from(md5, "hex").toString("base64")
      }

      const uploadFileToS3Response = await fetch(uploadTranslationsFileUrlResponse.data.url, {
        headers,
        method: "POST",
        body  : form
      })

      if(uploadFileToS3Response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.commit("setDraftPrivacyPolicyTranslations", draftPrivacyPolicyTranslations)
      }
    }
    context.commit("setDraftPrivacyPolicyTranslationsFileUploaded", true)
    context.commit("setUploadingDraftPrivacyPolicyTranslations", false)
  },

  /**
* This action initiates the download of a file associated with the provided link display name translations.
   * @param {*} context is the store.
   * @param {*} payload contains id and name of the link display name.
   */
  async downloadLinkDisplayNameTranslationFile(context, payload) {
    context.commit("setDownloadingLinkDisplayNameTranslation", true)

    const { id, name: linkDisplayName } = payload
    const httpClient                    = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getTranslationDownloadURLResponse = await httpClient.get(`/${id}/link-display-name-transl-dl-url`)
    if (getTranslationDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      const { url, md5 } = getTranslationDownloadURLResponse.data
      await downloadFile(url, md5, `${linkDisplayName}.xlsx`)
    }

    context.commit("setDownloadingLinkDisplayNameTranslation", false)
  },

  /**
   * This action is used to reset channel.
   * @param {*} context is the store.
   */
  async reset(context) {
    context.commit("setTriageTranslationFileUploaded", false)
    context.commit("setUploadingTriageTranslation", false)
    context.commit("setChannels", new Array())
    context.commit("setLoadingChannels", false)
    context.commit("setAddingChannel", false)
    context.commit("setChannelAdded", false)
    context.commit("setAddingChannelLogo", false)
    context.commit("setRemovingChannelLogo", false)
    context.commit("setChannelLogoRemoved", false)
    context.commit("setChannelLogoAdded", false)
    context.commit("setChannelAddError", undefined)
    context.commit("resetUpdatingChannel")
    context.commit("resetChannelUpdated")
    context.commit("resetChannelUpdateError")
    context.commit("setTranslationConfigurationsForChannel", new Object())
    context.commit("setLoadingTranslationConfigurations", false)
    context.commit("setTranslationConfigurationsUpdated", false)
    context.commit("setDownloadingChannelsOverview", false)
    context.commit("setLoadingChannelFormTemplates", false)
    context.commit("setAddingChannelFormTemplates", false)
    context.commit("setAddingChannelFormTemplates", false)
    context.commit("setAllChannelFormTemplates", new Object())
    context.commit("setRemovingChannelFormTemplate", false)
    context.commit("setChannelFormTemplateRemoved", false),
    context.commit("setDownloadingDescriptionTranslations", false)
    context.commit("setDownloadingTriageTranslations", false)
    context.commit("setDescriptionTranslationFileUploaded", false)
    context.commit("setUploadingDescriptionTranslation", false),
    context.commit("setPrivacyPolicyTranslations", new Object()),
    context.commit("setDraftPrivacyPolicyTranslations", new Object())
    context.commit("setLoadingPrivacyPolicyTranslations", false)
    context.commit("setUploadingDraftPrivacyPolicyTranslations", false)
    context.commit("setDraftPrivacyPolicyTranslationsFileUploaded", false)
    context.commit("setDownloadingLinkDisplayNameTranslation", false)
  }
}