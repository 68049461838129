import { computeLogs } from "@/utils"
export default {
  name : "Logs",
  props: {
    pLogs               : Array,
    pUsersMap           : Object,
    pGroupsMap          : Object,
    pCountriesMap       : Object,
    pLanguagesMap       : Object,
    pIssueSourcesMap    : Object,
    pIssueStatusesMap   : Object,
    pIssueResolutionsMap: Object,
    pDomainsMap         : Object,
    pIsPrintTemplate    : Boolean
  },
  computed: {
    logs() {
      return computeLogs(
        this.pLogs,
        {
          usersMap           : this.pUsersMap,
          statusesMap        : this.pIssueStatusesMap,
          domainsMap         : this.pDomainsMap,
          issueResolutionsMap: this.pIssueResolutionsMap
        },
        this
      )
    },
    getClassForUserName(){
      return this.pIsPrintTemplate ? "text-caption" : "text-caption secondary--text text--darken-2"
    }
  }
}
