export default {
  setLoadingChannels: (state, loadingChannels) => {
    state.loadingChannels = loadingChannels
  },
  setAddingChannel: (state, addingChannel) => {
    state.addingChannel = addingChannel
  },
  setChannelAdded: (state, channelAdded) => {
    state.channelAdded = channelAdded
  },
  setChannels: (state, channels) => {
    state.channels = channels
  },
  updateChannels: (state, channels) => {
    for (const channel of channels) {
      const index = state.channels.findIndex(stateChannel => stateChannel?.id === channel.id)
      if (index >= 0) {
        state.channels.splice(index, 1, { ...state.channels[index], ...channel })
      } else {
        state.channels.push(channel)
      }
    }
  },
  setUpdatingChannel: (state, properties) => {
    for (const property of properties) {
      state.updatingChannel[property] = true
    }
  },
  resetUpdatingChannel: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingChannel[property] = false
      }
    } else {
      Object.keys(state.updatingChannel).forEach(property => state.updatingChannel[property] = false)
    }
  },
  setUploadingTriageTranslation: (state, uploadingTriageTranslationFile) => {
    state.uploadingTriageTranslationFile = uploadingTriageTranslationFile
  },
  setTriageTranslationFileUploaded: (state, triageTranslationFileUploaded) => {
    state.triageTranslationFileUploaded = triageTranslationFileUploaded
  },
  setChannelUpdated: (state, properties) => {
    for (const property of properties) {
      state.channelUpdated[property] = true
      setTimeout(() => {
        state.channelUpdated[property] = false
      }, 5000)
    }
  },
  resetChannelUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.channelUpdated[property] = false
      }
    } else {
      Object.keys(state.channelUpdated).forEach(property => state.channelUpdated[property] = false)
    }
  },
  setChannelUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.channelUpdateError[property] = error
    }
  },
  resetChannelUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.channelUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.channelUpdateError).forEach(property => state.channelUpdateError[property] = undefined)
    }
  },
  setChannelAddError: (state, channelAddError) => {
    state.channelAddError = channelAddError
  },
  setFormsForChannel: (state, { id, forms }) => {
    state.formsOfChannels[id] = forms
  },
  pushFormsForChannel: (state, { id, forms }) => {
    if(state.formsOfChannels[id]) {
      state.formsOfChannels[id].push(...forms)
    } else {
      state.formsOfChannels = Object.assign({}, state.formsOfChannels, { id: forms })
    }
  },
  removeFormForChannel: (state, { id, form }) => {
    const forms = state.formsOfChannels[id]
    if (forms) {
      state.formsOfChannels[id].splice(forms.indexOf(form), 1)
    }
  },
  setAddingChannelForm: (state, addingChannelForm) => {
    state.addingChannelForm = addingChannelForm
  },
  setRemovingChannelForm: (state, removingChannelForm) => {
    state.removingChannelForm = removingChannelForm
  },
  setLoadingForms: (state, loadingForms) => {
    state.loadingForms = loadingForms
  },
  setTranslationConfigurationsForChannel: (state, { id, translationConfigurations }) => {
    state.translationConfigurations = Object.assign({}, state.translationConfigurations, {
      [id]: translationConfigurations
    })
  },
  setLoadingTranslationConfigurations: (state, loadingTranslationConfigurations) => {
    state.loadingTranslationConfigurations = loadingTranslationConfigurations
  },
  setUpdatingTranslationConfigurations: (state, updatingTranslationConfigurations) => {
    state.updatingTranslationConfigurations = updatingTranslationConfigurations
  },
  setTranslationConfigurationsUpdated: (state, translationConfigurationsUpdated) => {
    state.translationConfigurationsUpdated = translationConfigurationsUpdated
  },
  setAddingChannelLogo: (state, addingChannelLogo) => {
    state.addingChannelLogo = addingChannelLogo
  },
  setChannelLogoAdded: (state, channelLogoAdded) => {
    state.channelLogoAdded = channelLogoAdded
  },
  setRemovingChannelLogo: (state, removingChannelLogo) => {
    state.removingChannelLogo = removingChannelLogo
  },
  setChannelLogoRemoved: (state, channelLogoRemoved) => {
    state.channelLogoRemoved = channelLogoRemoved
  },
  setDownloadingChannelsOverview: (state, downloadingChannelsOverview) => {
    state.downloadingChannelsOverview = downloadingChannelsOverview
  },
  setAllChannelFormTemplates: (state, channelFormTemplates) => {
    state.formTemplatesOfChannels = channelFormTemplates
  },
  setLoadingChannelFormTemplates: (state, loadingChannelFormTemplates) => {
    state.loadingChannelFormTemplates = loadingChannelFormTemplates
  },
  setAddingChannelFormTemplates: (state, addingChannelFormTemplates) => {
    state.addingChannelFormTemplates = addingChannelFormTemplates
  },
  setChannelFormTemplatesAdded: (state, channelFormTemplatesAdded) => {
    state.channelFormTemplatesAdded = channelFormTemplatesAdded
  },
  setFormTemplatesForChannel: (state, { id, formTemplates }) => {
    state.formTemplatesOfChannels = { ...state.formTemplatesOfChannels, [id]: formTemplates }
  },
  pushFormTemplatesForChannel: (state, { id, formTemplates }) => {
    state.formTemplatesOfChannels = { ...state.formTemplatesOfChannels, [id]: formTemplates }
  },
  setRemovingChannelFormTemplate: (state, removingChannelFormTemplate) => {
    state.removingChannelFormTemplate = removingChannelFormTemplate
  },
  setChannelFormTemplateRemoved: (state, channelFormTemplateRemoved) => {
    state.channelFormTemplateRemoved = channelFormTemplateRemoved
  },
  removeFormTemplateOfChannel: (state, { id, formTemplateId }) => {
    state.formTemplatesOfChannels[id] = state.formTemplatesOfChannels[id]
      .filter(templateId => templateId !== formTemplateId)
  },
  setDownloadingDescriptionTranslations: (state, downloadingDescriptionTranslations) => {
    state.downloadingDescriptionTranslations = downloadingDescriptionTranslations
  },
  setDownloadingTriageTranslations: (state, downloadingTriageTranslations) => {
    state.downloadingTriageTranslations = downloadingTriageTranslations
  },
  setUploadingDescriptionTranslation: (state, uploadingDescriptionTranslationFile) => {
    state.uploadingDescriptionTranslationFile = uploadingDescriptionTranslationFile
  },
  setDescriptionTranslationFileUploaded: (state, descriptionTranslationFileUploaded) => {
    state.descriptionTranslationFileUploaded = descriptionTranslationFileUploaded
  },
  setLoadingPrivacyPolicyTranslations: (state, loadingPrivacyPolicyTranslations) => {
    state.loadingPrivacyPolicyTranslations = loadingPrivacyPolicyTranslations
  },
  setPrivacyPolicyTranslations: (state, privacyPolicyTranslations) => {
    state.privacyPolicyTranslations = privacyPolicyTranslations
  },
  setDraftPrivacyPolicyTranslations: (state, draftPrivacyPolicyTranslations) => {
    state.draftPrivacyPolicyTranslations = draftPrivacyPolicyTranslations
  },
  setUploadingDraftPrivacyPolicyTranslations: (state, uploadingDraftPrivacyPolicyTranslations) => {
    state.uploadingDraftPrivacyPolicyTranslations = uploadingDraftPrivacyPolicyTranslations
  },
  setDraftPrivacyPolicyTranslationsFileUploaded: (state, draftPrivacyPolicyTranslationsFileUploaded) => {
    state.draftPrivacyPolicyTranslationsFileUploaded = draftPrivacyPolicyTranslationsFileUploaded
  },
  setDownloadingLinkDisplayNameTranslation: (state, downloadingLinkDisplayNameTranslations) => {
    state.downloadingLinkDisplayNameTranslations = downloadingLinkDisplayNameTranslations
  }
}