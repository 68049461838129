import ThemisInput from "@/components/shared/input"
import { FIELD_TYPES, WIDGET, ARRAY, MAX_CHARACTER_LIMIT, AUTO_SAVE_INTERVAL } from "@/constants"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisCascadedInput from "@/components/shared/cascaded-input"

export default {
  name      : "IssueFormInstance",
  components: {
    ThemisInput,
    ThemisCascadedInput,
    ThemisDateTimePicker
  },
  props: {
    pFormInstance                   : Object,
    pOptionListItems                : Array,
    pIsUpdatingFormInstance         : Boolean,
    pIsFormInstanceUpdated          : Boolean,
    pFormInstanceUpdatePolicies     : Array,
    pFormInstanceRemovePolicies     : Array,
    pIsAddingFormInstanceFieldValues: Boolean
  },
  data: () => {
    return {
      formInstanceFieldValues                    : {},
      formInstanceFieldDraftValues               : {},
      updatingFormInstanceFieldId                : null,
      focussedLongTextFormInstanceFieldId        : null,
      longTextFieldCharacterLimit                : MAX_CHARACTER_LIMIT.ISSUE_FORM_LONG_TEXT_FIELD,
      shortTextFieldCharacterLimit               : MAX_CHARACTER_LIMIT.ISSUE_FORM_SHORT_TEXT_FIELD,
      isDateTimePickerOpen                       : {},
      showIssueFormInstanceDateMenu              : {},
      draftLongTextFormFieldValueAutoSaveInterval: null,
      draftLongTextFormFieldValueLastSavedTime   : undefined,
      savingFormInstanceFieldValueId             : null,
      isFormInstanceDraftValueCleared            : false
    }
  },
  beforeDestroy() {
    if (this.draftLongTextFormFieldValueAutoSaveInterval) {
      clearInterval(this.draftLongTextFormFieldValueAutoSaveInterval)
    }
  },
  computed: {
    formInstanceFields() {
      return this.pFormInstance.formInstanceFields
    },
    currentFormInstanceUpdatePolicies() {
      return this.pFormInstanceUpdatePolicies.find(formInstanceUpdatePolicy =>
        formInstanceUpdatePolicy.id === this.pFormInstance.id
      )
    },
    canUpdateFormInstanceFieldValue() {
      return this.currentFormInstanceUpdatePolicies?.set?.value !== undefined
    },
    currentFormInstanceRemovePolicies() {
      return this.pFormInstanceRemovePolicies.find(issueFormInstanceRemovePolicy =>
        issueFormInstanceRemovePolicy.id === this.pFormInstance.id
      )
    },
    canRemoveFormInstance() {
      return this.currentFormInstanceRemovePolicies?.set?.remove !== undefined
    }
  },
  methods: {
    handleLongTextFormFieldInput(formInstanceFieldId, text) {
      this.formInstanceFieldDraftValues[formInstanceFieldId] = text
      this.saveLongTextFormFieldValuesOnInterval(formInstanceFieldId)
    },
    currentFormInstanceFieldValues(formInstanceFieldId) {
      return this.pFormInstance.formInstanceFields.find(
        formInstanceField => formInstanceField.id === formInstanceFieldId
      )?.formInstanceFieldValues[0]
    },
    saveLongTextFormFieldValuesOnInterval(formInstanceFieldId) {
      if (!this.draftLongTextFormFieldValueAutoSaveInterval
        && this.focussedLongTextFormInstanceFieldId === formInstanceFieldId) {

        this.draftLongTextFormFieldValueAutoSaveInterval = setInterval(() => {
          this.draftLongTextFormFieldValueLastSavedTime = new Date()

          if (this.hasDraftLongTextFormFieldChanged(formInstanceFieldId)) {
            if (this.formInstanceFieldDraftValues[formInstanceFieldId]?.length < this.longTextFieldCharacterLimit) {
              this.$emit("saveFormInstanceFieldValues", {
                id   : this.currentFormInstanceFieldValues(formInstanceFieldId).id,
                value: this.formInstanceFieldDraftValues[formInstanceFieldId] ?? ""
              })
            }
          }
        }, AUTO_SAVE_INTERVAL)
      }
    },
    hasDraftLongTextFormFieldChanged(formInstanceFieldId) {
      if (this.formInstanceFieldDraftValues[formInstanceFieldId] === "" && this.currentFormInstanceFieldValues(formInstanceFieldId).draftValue === null) {
        this.draftLongTextFormFieldValueAutoSaveInterval = undefined
        return false
      }

      return this.currentFormInstanceFieldValues(formInstanceFieldId)?.draftValue
        !== this.formInstanceFieldDraftValues[formInstanceFieldId] &&
        this.currentFormInstanceFieldValues(formInstanceFieldId)?.value
        !== this.formInstanceFieldDraftValues[formInstanceFieldId]
    },
    longTextFormFieldValueToDisplay(formInstanceFieldId) {
      if (this.isFormInstanceDraftValueCleared) {
        return this.formInstanceFieldDraftValues[formInstanceFieldId]
      } else {
        const currentFormInstanceFieldValues = this.pFormInstance.formInstanceFields.find(
          formInstanceField => formInstanceField.id === formInstanceFieldId
        )?.formInstanceFieldValues[0]

        return this.focussedLongTextFormInstanceFieldId === formInstanceFieldId
         && this.formInstanceFieldDraftValues[formInstanceFieldId]
          ? this.formInstanceFieldDraftValues[formInstanceFieldId]
          : currentFormInstanceFieldValues.value
      }
    },
    handleLongTextFormFieldFocus(id, onFocus) {
      onFocus()
      this.focussedLongTextFormInstanceFieldId = id
    },
    handleLongTextFormFieldBlur(onBlur, $event) {
      clearInterval(this.draftLongTextFormFieldValueAutoSaveInterval)
      this.draftLongTextFormFieldValueAutoSaveInterval = undefined
      onBlur()
      if ($event.relatedTarget?.dataset?.cy === "2353") {
        return
      }
      if ($event.relatedTarget?.dataset?.cy === "2354") {
        return
      }
      this.focussedLongTextFormInstanceFieldId = null
    },
    hasLongTextFormFieldDraftValue(formInstanceFieldId) {
      return !!this.formInstanceFieldDraftValues[formInstanceFieldId]
    },
    getLongTextFormFieldDetailText(formInstanceFieldId) {
      if (this.pIsAddingFormInstanceFieldValues) {
        return this.$t("2072")
      }
      if (this.formInstanceFieldDraftValues[formInstanceFieldId]) {
        if (this.focussedLongTextFormInstanceFieldId !== formInstanceFieldId) {
          return this.$t("2074")
        }
        return this.$t("2075", { time: this.$moment(this.draftLongTextFormFieldValueLastSavedTime).format("D MMMM YYYY HH:mm (UTCZ)") })
      }
    },
    isOptionListCascaded(formInstanceField) {
      const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
        optionList.optionListId === formInstanceField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    getItemsForSelectionCascadedOptionList(formInstanceField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(formInstanceField.type)) {
        const sortedOptionListItems = this.pOptionListItems
          .filter(optionListItem => optionListItem.optionListId === formInstanceField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple = this.isFieldTypeMultipleOptionList(formInstanceField)
        const options    = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)

        let currentIssueFormInstanceFieldValue
        if (this.isFieldTypeMultipleOptionList(formInstanceField) ||
          this.isFieldTypeSingleOptionList(formInstanceField)) {
          currentIssueFormInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
        } else {
          currentIssueFormInstanceFieldValue = [this.formInstanceFieldValues[formInstanceField.id]]
        }

        for (const issueFormInstanceFieldValue of currentIssueFormInstanceFieldValue) {
          const isOptionListItemExists = sortedOptionListItems.find(option =>
            option.name === issueFormInstanceFieldValue
          )
          if (!isOptionListItemExists && issueFormInstanceFieldValue) {
            options.push({ name: issueFormInstanceFieldValue, children: [] })
          }
        }
        return options
      }
    },
    getItemsForSelection(formInstanceField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(formInstanceField.type)) {
        const options = this.pOptionListItems
          .filter(optionListItem => optionListItem.optionListId === formInstanceField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id  : optionListItem.id,
            name: optionListItem.name
          }))

        let currentIssueFormInstanceFieldValue
        if (formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
          currentIssueFormInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
        } else {
          currentIssueFormInstanceFieldValue = [this.formInstanceFieldValues[formInstanceField.id]]
        }

        for (const issueFormInstanceFieldValue of currentIssueFormInstanceFieldValue) {
          const isOptionListItemExists = options.find(option =>
            option.name === issueFormInstanceFieldValue
          )
          if (!isOptionListItemExists && issueFormInstanceFieldValue) {
            options.push({ name: issueFormInstanceFieldValue })
          }
        }
        return options
      }
    },
    onNodeSelect(selectedNodes, formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = selectedNodes
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })

      return result
    },
    getFormInstanceFieldValue(formInstanceField) {
      let fieldValue

      if (this.isFieldTypeMultipleOptionList(formInstanceField) ||
        (this.isFieldTypeSingleOptionList(formInstanceField) && this.isOptionListCascaded(formInstanceField))) {
        fieldValue = formInstanceField.formInstanceFieldValues
          .filter(
            formInstanceFieldValue => formInstanceFieldValue.value
          ).map(
            formInstanceFieldValue => formInstanceFieldValue.value
          )
      } else {
        fieldValue = formInstanceField.formInstanceFieldValues[ARRAY.FIRST]?.value
      }
      if (formInstanceField.type === FIELD_TYPES.DATE_TIME.value) {
        return fieldValue ? new Date(fieldValue) : null
      }
      return fieldValue
    },
    getFormInstanceFieldDraftValue(formInstanceField) {
      let draftValue = null
      if (formInstanceField.type === FIELD_TYPES.LONG_TEXT.value) {
        draftValue = formInstanceField.formInstanceFieldValues[ARRAY.FIRST]?.draftValue
      }
      return draftValue?.value
    },
    hasFormInstanceFieldValueChanged(formInstanceField) {
      const originalIssueFormInstanceFieldValue = this.getFormInstanceFieldValue(formInstanceField)
      if (formInstanceField.type === FIELD_TYPES.LONG_TEXT.value) {
        return this.hasLongTextFormFieldDraftValue(formInstanceField.id)
      }
      if (formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value) {
        return JSON.stringify(originalIssueFormInstanceFieldValue.slice().sort()) !==
          JSON.stringify(this.formInstanceFieldValues[formInstanceField.id].slice().sort())
      }
      return this.formInstanceFieldValues[formInstanceField.id] !== originalIssueFormInstanceFieldValue
    },
    isFormInstanceFieldUpdating(formInstanceField) {
      return this.pIsUpdatingFormInstance && this.updatingFormInstanceFieldId === formInstanceField.id
    },
    isFieldTypeMultipleOptionList(formInstanceField) {
      return formInstanceField.type === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    isFieldTypeSingleOptionList(formInstanceField) {
      return formInstanceField.type === FIELD_TYPES.OPTION_LIST.value
    },
    handleOptionListInputOnBlurEvent(onBlur, formInstanceField) {
      onBlur()
      if (this.isFieldTypeMultipleOptionList(formInstanceField)) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    handleOptionListInputOnChangeEvent(formInstanceField) {
      if (!this.isFieldTypeMultipleOptionList(formInstanceField)) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    updateFormInstanceFieldValueForLongText(formInstanceField) {
      const data = [{
        formInstanceFieldId: formInstanceField.id,
        value              : this.formInstanceFieldDraftValues[formInstanceField.id]
      }]

      this.$emit("updateFormInstance", {
        id: this.pFormInstance.id,
        data
      })
      this.savingFormInstanceFieldValueId                     = formInstanceField.id
      this.formInstanceFieldDraftValues[formInstanceField.id] = null
      this.isFormInstanceDraftValueCleared                    = false
    },
    updateFormInstanceFieldValue(formInstanceField) {
      if (formInstanceField.type === FIELD_TYPES.DATE.value) {
        this.showIssueFormInstanceDateMenu[formInstanceField.id] = false
      }
      const fieldValues = this.formInstanceFieldValues[formInstanceField.id]
      if (this.hasFormInstanceFieldValueChanged(formInstanceField)) {
        const dataToBeUpdated               = []
        const isFieldTypeMultipleOptionList = this.isFieldTypeMultipleOptionList(formInstanceField)
        const isFieldTypeSingleOptionList   = this.isFieldTypeSingleOptionList(formInstanceField)

        if (isFieldTypeMultipleOptionList ||
          (isFieldTypeSingleOptionList && this.isOptionListCascaded(formInstanceField))) {
          if (fieldValues.length) {
            for (const fieldValue of fieldValues) {
              dataToBeUpdated.push({
                formInstanceFieldId: formInstanceField.id,
                value              : fieldValue
              })
            }
          } else {
            dataToBeUpdated.push({
              formInstanceFieldId: formInstanceField.id,
              value              : null
            })
          }
        } else {
          dataToBeUpdated.push({
            formInstanceFieldId: formInstanceField.id,
            value              : fieldValues
          })
        }

        this.$emit("updateFormInstance", {
          id  : this.pFormInstance.id,
          data: dataToBeUpdated
        })
        this.updatingFormInstanceFieldId = formInstanceField.id
      }
    },
    isFormInstanceFieldWidgetTextarea(formInstanceField) {
      return formInstanceField.widget === WIDGET.TEXTAREA
    },
    displayLongTextFieldActions(formInstanceField) {
      return this.focussedLongTextFormInstanceFieldId === formInstanceField.id ||
        this.hasFormInstanceFieldValueChanged(formInstanceField)
    },
    handleNumberFieldUpdateEvent(formInstanceField, onBlur) {
      if (onBlur) {
        onBlur()
      }
      const formInstanceFieldValue = this.formInstanceFieldValues[formInstanceField.id]
      const isNumber               = Number.isInteger(formInstanceFieldValue)
      const isEmptyString          = formInstanceFieldValue?.toString().length === 0

      if (isNumber) {
        this.formInstanceFieldValues[formInstanceField.id] =
          this.formInstanceFieldValues[formInstanceField.id].toString()
      } else if (isEmptyString) {
        this.formInstanceFieldValues[formInstanceField.id] = null
      }

      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleNumberFieldKeypress(event, formInstanceField) {
      const formInstanceFieldValue        = this.formInstanceFieldValues[formInstanceField.id]
      const isFormInstanceFieldValueEmpty = !formInstanceFieldValue && formInstanceFieldValue !== 0
      const isKeyPressedADash             = event.key === "-"
      const isKeyPressedNotANumber        = isNaN(event.key)
      if (isKeyPressedNotANumber) {
        if (!(isFormInstanceFieldValueEmpty && isKeyPressedADash)) {
          event.preventDefault()
        }
      }
    },
    handleLongTextFieldCancelSave(formInstanceField) {
      this.isFormInstanceDraftValueCleared               = false
      this.focussedLongTextFormInstanceFieldId           = null
      this.formInstanceFieldValues[formInstanceField.id] = this.getFormInstanceFieldValue(formInstanceField)
      if (this.hasLongTextFormFieldDraftValue(formInstanceField.id)) {

        this.$emit("saveFormInstanceFieldValues", {
          id   : this.currentFormInstanceFieldValues(formInstanceField.id).id,
          value: ""
        })
      }
      this.formInstanceFieldDraftValues[formInstanceField.id] = ""
    },
    handleUpdateDateTime(formInstanceField) {
      this.isDateTimePickerOpen[formInstanceField.id] = false
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleResetDateTimePicker(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = this.getFormInstanceFieldValue(formInstanceField)
    },
    handleCloseDateTimePicker(formInstanceField) {
      this.isDateTimePickerOpen[formInstanceField.id] = false
      this.handleResetDateTimePicker(formInstanceField)
    },
    dateTimeForDisplaying(formInstanceField) {
      if (this.formInstanceFieldValues[formInstanceField.id]) {
        return this.$moment(this.formInstanceFieldValues[formInstanceField.id]).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    handleShortTextFieldUpdateEvent(formInstanceField, onBlur) {
      onBlur()
      const shortTextFieldValue               = this.formInstanceFieldValues[formInstanceField.id]
      const isShortTextFieldNullOrWithinLimit = !shortTextFieldValue ||
        shortTextFieldValue.length <= this.shortTextFieldCharacterLimit

      if (isShortTextFieldNullOrWithinLimit) {
        this.updateFormInstanceFieldValue(formInstanceField)
      }
    },
    handleShortTextFieldInputOnEnter(formInstanceFieldId) {
      this.$refs[`input_short_text_field_${formInstanceFieldId}`][ARRAY.FIRST].blur()
    },
    handleDateClearEvent(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = null
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    handleDateTimeClearEvent(formInstanceField) {
      this.formInstanceFieldValues[formInstanceField.id] = null
      this.updateFormInstanceFieldValue(formInstanceField)
    },
    issueFormInstanceDateDisplay(formInstanceField) {
      let displayDate = this.formInstanceFieldValues[formInstanceField.id]
      if (displayDate) {
        displayDate = this.$moment(displayDate).format("DD MMMM YYYY")
      }
      return displayDate
    },
    handleLongTextClearEvent(formInstanceFieldId) {
      this.isFormInstanceDraftValueCleared = true
      if (this.hasLongTextFormFieldDraftValue(formInstanceFieldId)) {
        this.$emit("saveFormInstanceFieldValues", {
          id   : this.currentFormInstanceFieldValues(formInstanceFieldId).id,
          value: ""
        })
      }
    },
    shouldDisableSaveButton(formInstanceFieldId) {
      const isFieldUpdating = this.isFormInstanceFieldUpdating(formInstanceFieldId)
      const hasDraftValue   = this.hasLongTextFormFieldDraftValue(formInstanceFieldId)
      const isValueChanged  = this.currentFormInstanceFieldValues(formInstanceFieldId)?.value !==
       this.longTextFormFieldValueToDisplay(formInstanceFieldId)

      if (this.isFormInstanceDraftValueCleared) {
        return !(isValueChanged && !isFieldUpdating)
      } else {
        return !(hasDraftValue && isValueChanged && !isFieldUpdating)
      }
    }
  },
  watch: {
    pFormInstance: {
      immediate: true,
      handler  : function(formInstance) {
        const formInstanceFieldValues       = {}
        const formInstanceFieldDraftValues  = {}
        const showIssueFormInstanceDateMenu = {}
        for (const formInstanceField of formInstance.formInstanceFields) {
          formInstanceFieldValues[formInstanceField.id]      = this.getFormInstanceFieldValue(formInstanceField)
          formInstanceFieldDraftValues[formInstanceField.id] = this.getFormInstanceFieldDraftValue(formInstanceField)
          if (formInstanceField.type === FIELD_TYPES.DATE.value) {
            showIssueFormInstanceDateMenu[formInstanceField.id] = false
          }
        }
        this.formInstanceFieldValues       = { ...formInstanceFieldValues }
        this.formInstanceFieldDraftValues  = { ...formInstanceFieldDraftValues }
        this.showIssueFormInstanceDateMenu = { ...showIssueFormInstanceDateMenu }
      }
    },
    pIsFormInstanceUpdated: {
      immediate: true,
      handler  : function(value) {
        if (value) {
          this.updatingFormInstanceFieldId                                       = null
          this.focussedLongTextFormInstanceFieldId                               = null
          this.formInstanceFieldDraftValues[this.savingFormInstanceFieldValueId] = null
          this.savingFormInstanceFieldValueId                                    = null
        }
      }
    }
  }
}