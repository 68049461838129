import { mapActions, mapGetters } from "vuex"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "LinkDisplayNameSidePanel",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data() {
    return {
      showDisableTranslationDialog: false,
      localChannel                : undefined
    }
  },
  methods: {
    ...mapActions({
      updateChannel                         : "channels/updateChannel",
      notify                                : "shared/notify",
      downloadLinkDisplayNameTranslationFile: "channels/downloadLinkDisplayNameTranslationFile"
    }),
    handleTranslationChange() {
      if (this.isTranslationEnabled) {
        this.showDisableTranslationDialog = true
      } else {
        this.updateChannel({
          id                              : this.localChannel.id,
          allowLinkDisplayNameTranslations: this.localChannel.allowLinkDisplayNameTranslations
        })
      }
    },
    resetTranslationChange() {
      this.showDisableTranslationDialog                  = false
      this.localChannel.allowLinkDisplayNameTranslations = this.channel.allowLinkDisplayNameTranslations
    }
  },
  computed: {
    ...mapGetters({
      channels                               : "channels/channels",
      isUpdatingLinkDisplayNameTranslations  : "channels/isUpdatingLinkDisplayNameTranslations",
      isLinkDisplayNameTranslationsUpdated   : "channels/isLinkDisplayNameTranslationsUpdated",
      isDownloadingLinkDisplayNameTranslation: "channels/isDownloadingLinkDisplayNameTranslation"
    }),
    channel() {
      return this.channels.find(channels => channels.id === +this.$route.params.id)
    },
    isTranslationEnabled() {
      return this.channel.allowLinkDisplayNameTranslations
    }
  },
  watch: {
    channel: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localChannel) {
          this.localChannel = { ...newValue }
        }
      }
    },
    isLinkDisplayNameTranslationsUpdated: {
      handler: function(value) {
        if (value) {
          if (!this.isTranslationEnabled) {
            this.notify({
              type: "success",
              text: "2469"
            })
            this.showDisableTranslationDialog = false
          }
        }
      }
    },
    isTranslatingLinkDisplayName: {
      handler: function(newValue) {
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.DISABLE_LINK_DISPLAY_NAME_TRANSLATION.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}