import { CookieStorage } from "amazon-cognito-identity-js"
import { format } from "@/utils"

export const HTTP_STATUS_CODE = {
  OK                   : 200,
  OK_WITH_NO_CONTENT   : 204,
  UNAUTHORIZED         : 401,
  FORBIDDEN            : 403,
  NOT_FOUND            : 404,
  METHOD_NOT_ALLOWED   : 405,
  BAD_REQUEST          : 400,
  CONFLICT             : 409,
  PAYLOAD_TOO_LARGE    : 413,
  FAILED_DEPENDENCY    : 424,
  INTERNAL_SERVER_ERROR: 500
}
export const ISSUE_STATUS = {
  NEW        : 1,
  IN_PROGRESS: 2,
  CLOSED     : 3,
  TO_DO      : 4,
  DONE       : 5
}

export const ISSUE_STATUS_CATEGORY = {
  NEW        : "New",
  IN_PROGRESS: "In progress",
  DONE       : "Done"
}

export const INTEGER = {
  ZERO: 0
}
export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  INFO   : "info",
  ERROR  : "error"
}
export const SPECIAL_CHARACTERS = {
  QUERY: "?"
}
export const REPORT_STATUS = {
  CLOSED: "closed",
  NEW   : "new"
}

export const MESSAGE_STATUS = {
  DRAFT: "draft",
  NEW  : "new",
  SEEN : "seen"
}

export const COOKIE_OPTIONS = {
  expires : null,
  domain  : window.location.host.split(":")[0],
  secure  : true,
  path    : "/",
  sameSite: "lax"
}

export const COGNITO_USER_POOL_DATA = (UserPoolId, ClientId) => ({
  UserPoolId: UserPoolId,
  ClientId  : ClientId,
  Storage   : new CookieStorage(COOKIE_OPTIONS)
})

export const MFA = {
  SUCCESS       : "SUCCESS",
  TOTP_PREFERRED: "SOFTWARE_TOKEN_MFA",
  SETUP_REQUIRED: "MFA_SETUP",
  TOTP_URL      : "otpauth://totp/{0}?{1}"
}

export const ERROR = {
  DUPLICATE: "duplicate"
}

export const COGNITO_AUTHENTICATION_MESSAGE = {
  EXCEEDED_ATTEMPTS    : "Password attempts exceeded",
  NEW_PASSWORD_REQUIRED: "New Password Required",
  INVALID_CREDENTIALS  : "Incorrect username or password.",
  USER_DISABLED        : "User is disabled.",
  USER_VALIDATION_ERROR: "The SSO user validation attribute does not match the expected configuration value."
}

export const EXCEPTION_STATUS = {
  OPEN    : "Open",
  APPROVED: "Approved",
  DECLINED: "Declined"
}

export const REPORT_ANONYMISATION_STATUS = {
  DONE         : "done",
  SCHEDULED    : "scheduled",
  NOT_SCHEDULED: null
}

export const TRANSLATION_PREFERENCES = {
  NO_TRANSLATION   : 1,
  MACHINE_PREFERRED: 2,
  MACHINE_ONLY     : 3,
  HUMAN_ONLY       : 4
}

export const ASK_ORGANISATION_CODE_FOR_WEB_ITEMS = {
  true: {
    value: true,
    text : "218"
  },
  false: {
    value: false,
    text : "219"
  }
}

export const REPORT_SOURCE = {
  APP  : "app",
  PHONE: "phone",
  WEB  : "web"
}

export const IDP_LOGIN_URL = (domainUrl, clientUserPoolClientId, redirectUrl, verificationCode) => `${domainUrl}/authorize?identity_provider=default&response_type=code&client_id=${clientUserPoolClientId}&redirect_uri=${redirectUrl}&state=${verificationCode}&scope=email profile openid`
export const IDP_LOGOUT_URL = (domainUrl, clientUserPoolClientId, redirectUrl) => `${domainUrl}/logout?client_id=${clientUserPoolClientId}&logout_uri=${redirectUrl}`

export const SPEAKUP_ISSUE_ACKNOWLEDGEMENT = {
  DATE_OF_RECEIPT                          : "date_of_receipt",
  DATE_OF_FIRST_REPLY_AVAILABLE_TO_REPORTER: "date_of_first_reply_available_to_reporter"
}

export const CONFIGURATIONS = {
  ASK_ORGANISATION_CODE_FOR_WEB: "ASK_ORGANISATION_CODE_FOR_WEB",
  TRANSLATION                  : "TRANSLATION",
  SPEAKUP_ISSUE_ACKNOWLEDGEMENT: "SPEAKUP_ISSUE_ACKNOWLEDGEMENT",
  CLIENT_USER_POOL_ID          : "CLIENT_USER_POOL_ID",
  SSO                          : "SSO",
  DEFAULT_SSO                  : "DEFAULT_SSO",
  ID                           : "ID",
  AI_ANONYMISATION             : "AI_ANONYMISATION",
  USERS_COUNT                  : "USERS_COUNT",
  CHANNELS_COUNT               : "CHANNELS_COUNT",
  ISSUE_LINKS                  : "ISSUE_LINKS",
  EXTENDED_SEARCH              : "EXTENDED_SEARCH",
  SHAREPOINT_INTEGRATION       : "SHAREPOINT_INTEGRATION",
  TASKS                        : "TASKS",
  DATA_RETENTION_PERIODS       : "DATA_RETENTION_PERIODS",
  ISSUE_FIELDS                 : "ISSUE_FIELDS",
  ISSUE_FORMS                  : "ISSUE_FORMS",
  REPORTER_INTAKE_FORMS        : "REPORTER_INTAKE_FORMS",
  AUTOMATION_ACTION_SEND_EMAIL : "AUTOMATION_ACTION_SEND_EMAIL",
  AUTOMATION_ACTION_MOVE_ISSUE : "AUTOMATION_ACTION_MOVE_ISSUE",
  MENTION_COMMENTS             : "MENTION_COMMENTS",
  WORKFLOWS                    : "WORKFLOWS",
  DATA_STORAGE_LOCATION        : "DATA_STORAGE_LOCATION",
  TERMS_OF_USE                 : "TERMS_OF_USE",
  TRIAGE                       : "TRIAGE",
  REPLY_TEMPLATES              : "REPLY_TEMPLATES",
  AI_PREFILL_FIELDS            : "AI_PREFILL_FIELDS",
  CUSTOM_ROLES                 : "ROLES",
  EXPORT_ALL_DATA              : "EXPORT_ALL_DATA",
  AI_SUGGESTIONS               : "AI_SUGGESTIONS",
  ENFORCE_MFA                  : "ENFORCE_MFA",
  SAVED_FILTERS                : "SAVED_FILTERS",
  AI_CASE_SUMMARISATION        : "AI_CASE_SUMMARISATION",
  AI_SUMMARISATION             : "AI_SUMMARISATION",
  CUSTOM_ANALYTICS             : "CUSTOM_ANALYTICS",
  SHOW_PHONE_NUMBERS           : "SHOW_PHONE_NUMBERS",
  BULK_IMPORT_USERS            : "BULK_IMPORT_USERS",
  AI_SPEECH_TO_TEXT            : "AI_SPEECH_TO_TEXT",
  CLIENT_AI_SPEECH_TO_TEXT     : "CLIENT_AI_SPEECH_TO_TEXT"
}

export const SSO_STATE = {
  CONFIGURED: "configured",
  ENABLED   : "enabled"
}

export const CLIENT_FEATURES = {
  "issue-fields": {
    "getterName": ["isIssueFieldsEnabled"],
    "routes"    : ["issue-fields", "issue-field"]
  },
  "issue-form-templates": {
    "getterName": ["isIssueFormsEnabled"],
    "routes"    : ["issue-form-templates", "issue-form-template"]
  },
  "issue-forms": {
    "getterName": "isIssueFormsEnabled",
    "routes"    : ["issue-forms", "issue-form"]
  },
  "reporter-intake-forms": {
    "getterName": "isReporterIntakeFormsEnabled",
    "routes"    : ["reporter-intake-forms", "reporter-intake-form"]
  },
  "option-lists": {
    "dependencies": [
      "reporter-intake-forms",
      "issue-forms",
      "issue-fields"
    ],
    "routes": [
      "option-lists",
      "fields",
      "reporter-intake-forms",
      "issue-forms",
      "option-list",
      "field",
      "reporter-intake-form",
      "issue-form",
      "issue-form-field-configuration",
      "reporter-intake-form-template-configuration"
    ]
  },
  "fields": {
    "dependencies": [
      "reporter-intake-forms",
      "issue-forms",
      "issue-fields",
      "issue-form-templates"
    ],
    "routes": [
      "option-lists",
      "fields",
      "reporter-intake-forms",
      "issue-forms",
      "option-list",
      "field",
      "reporter-intake-form",
      "issue-form",
      "issue-form-field-configuration",
      "reporter-intake-form-template-configuration"
    ]
  },
  "automations": {
    "getterName": [
      "isAutomationActionSendEmailEnabled",
      "isAutomationActionMoveIssueEnabled"
    ],
    "routes": [
      "automations",
      "automation-add",
      "automation-edit"
    ]
  },
  "reply-templates": {
    "getterName": [
      "isReplyTemplatesEnabled"
    ],
    "routes": [
      "reply-templates",
      "reply-template"
    ]
  },
  "saved-filters": {
    "getterName": [
      "isSavedFiltersEnabled"
    ],
    "routes": [
      "filters"
    ]
  },
  "export": {
    "getterName": [
      "isExportAllDataEnabled",
      "isSharePointIntegrationEnabled"
    ],
    "routes": [
      "export"
    ]
  }
}

export const ISSUE_DOCUMENT_SIZE_LIMIT_IN_BYTES = 2147483648
export const TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES = 524288000

export const AUTO_SAVE_INTERVAL = 5000

export const VALIDATION_ERROR = {
  FIELD: {
    NAME               : "name",
    SYSTEM_NAME        : "systemName",
    NAME_OPTION_LIST_ID: "name, optionListId"
  },
  TYPE: {
    DUPLICATE: "duplicate"
  }
}

export const FORMS = {
  ISSUE : "ISSUE_FORM",
  REPORT: "INTAKE_FORM"
}

export const WORKFLOW_UPDATE_TRANSITION_SCREEN = {
  ADD_SCREEN   : "ADD_SCREEN",
  UPDATE_SCREEN: "UPDATE_SCREEN",
  REMOVE_SCREEN: "REMOVE_SCREEN"
}

const DOCUMENT_TYPES = {
  PDF          : "application/pdf",
  RTF          : "application/rtf",
  DOC          : "application/msword",
  XLS          : "application/vnd.ms-excel",
  PPT          : "application/vnd.ms-powerpoint",
  DOCX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPTX         : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  GIF          : "image/gif",
  PNG          : "image/png",
  JPG          : "image/jpeg",
  JPEG         : "image/jpeg",
  MP3          : "audio/mpeg",
  MP4          : "video/mp4",
  XWAV         : "audio/x-wav",
  WAV          : "audio/wav",
  WMV          : "audio/x-ms-wmv",
  MPEG         : "video/mpeg",
  MPG          : "video/mpeg",
  MPE          : "video/mpeg",
  MOV          : "video/quicktime",
  MSG          : "application/vnd.ms-outlook",
  MSG_EXTENSION: ".msg",
  AVI          : "video/x-msvideo",
  DOT          : "application/msword",
  XML          : "text/xml",
  DOTX         : "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  CSV          : "text/csv",
  HTML         : "text/html",
  HTM          : "text/html",
  TPL          : "text/html",
  XLW          : "application/vnd.ms-excel",
  XLT          : "application/vnd.ms-excel",
  XLTX         : "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  PPS          : "application/vnd.ms-powerpoint",
  POT          : "application/vnd.ms-powerpoint",
  POTX         : "application/vnd.openxmlformats-officedocument.presentationml.template",
  BMP          : "image/bmp",
  DXF          : "application/dxf",
  TIF          : "image/tif",
  TIFF         : "image/tiff",
  ODT          : "application/vnd.oasis.opendocument.text",
  OTH          : "application/vnd.oasis.opendocument.text-web",
  ODM          : "application/vnd.oasis.opendocument.text-master",
  ODS          : "application/vnd.oasis.opendocument.spreadsheet",
  OTS          : "application/vnd.oasis.opendocument.spreadsheet-template",
  ODP          : "application/vnd.oasis.opendocument.presentation",
  ODG          : "application/vnd.oasis.opendocument.graphics",
  OTP          : "application/vnd.oasis.opendocument.presentation-template",
  RTF_TEXT     : "text/rtf",
  WMV_VIDEO    : "video/x-ms-wmv",
  AVI_VIDEO    : "video/avi",
  X_DXF        : "application/x-dxf",
  EML          : "message/rfc822",
  HEIC         : "image/heic",
  HEIF         : "image/heif"
}

export const ISSUE_DOCUMENTS_TYPES = {
  ...DOCUMENT_TYPES
}

export const IMPORT_CASES_DOCUMENT_TYPE = {
  CSV: "text/csv"
}

export const IMPORT_USERS_DOCUMENT_TYPE = {
  CSV: "text/csv"
}

export const UNSUPPORTED_MIME_TYPE = {
  MSG: "application/vnd.ms-outlook"
}

export const TRANSLATION_FILE_UPLOAD_TYPE = {
  XLSX: DOCUMENT_TYPES.XLSX
}

export const ROLE_ASSIGNMENT_CATEGORY = {
  USER : "user",
  GROUP: "group"
}

export const ISSUE_ACCESS_UPDATE_ROLES = [2]

export const ISSUE_ROLES = [2, 3, 4]

export const FILE_NAME = {
  GET_ANALYTICS_EXPORT            : date => `speakup-analytics-${date}.xlsx`,
  CHANNEL_DESCRIPTION_TRANSLATIONS: channelName => `${channelName}_description_translations.xlsx`,
  CHANNEL_TRIAGE_TRANSLATIONS     : channelName => `${channelName}_triage_translations.xlsx`,
  ALL_DATA_EXPORT_ZIP             : "all-data-export.zip",
  ISSUES_EXPORT                   : "issues-export.xlsx"
}

export const DATA_EXPORT_STATUS = {
  PENDING  : "pending",
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const TRANSLATION_UPLOAD_STATUS = {
  INITIATED: "initiated",
  SUCCESS  : "success",
  FAILURE  : "failure"
}

export const OPTION_LIST_EXPORT_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const ISSUE_SEARCH_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const ITEM_COMPARISON = {
  FIRST_ITEM_IS_HIGHER: 1,
  FIRST_ITEM_IS_LOWER : -1
}

export const DATA_EXPORT_TYPE = {
  ALL                        : "all",
  ANALYTICS                  : "analytics",
  ISSUES_WITH_ALL_FIELDS     : "issues with all fields",
  ISSUES_WITH_SELECTED_FIELDS: "issues with selected fields",
  ISSUE_DOCX                 : "issue docx",
  ISSUE_PDF                  : "issue pdf"
}

export const AI_ASSISTANCE_STATUS = {
  INITIATED: "initiated",
  DONE     : "done",
  FAILED   : "failed"
}

export const AI_ASSISTANCE_TYPES = {
  ISSUE_SUMMARISATION    : "ISSUE_SUMMARISATION",
  ISSUE_FIELDS_SUGGESTION: "ISSUE_FIELDS_SUGGESTION"
}

export const BULK_IMPORT_STATUS = {
  INITIATED: "initiated",
  COMPLETED: "completed",
  FAILED   : "failed"
}

export const BULK_IMPORT_TYPES = {
  ISSUE: "issue",
  USER : "user"
}

export const MESSAGE_FILTER_STATUS = {
  READ  : "read",
  UNREAD: "unread"
}

export const INITIAL_RESPONSE_STATUS = {
  RESPONDED    : "responded",
  NOT_RESPONDED: "not responded"
}

export const CHANNEL_TRIAGE_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 10000

export const DATA_EXPORT_POLL_INTERVAL = 20000

export const ISSUES_DATA_EXPORT_POLL_INTERVAL = 5000

export const ISSUE_EXPORT_POLL_INTERVAL = 2000

export const FIELD_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 10000

export const CHANNEL_DESCRIPTION_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 10000

export const ISSUE_SEARCH_POLL_INTERVAL = 1000

export const ISSUE_STATUS_MIGRATION_POLL_INTERVAL = 1000

export const PRIVACY_POLICY_TEXT_TRANSLATION_STATUS_POLL_INTERVAL = 1000

export const AI_ASSISTANCE_POLL_INTERVAL = 5000

export const MILLISECONDS_TO_SHOW_LOGOUT_NOTIFICATION = 240000

export const AI_SUMMARISATION_COPIED_TEXT_INTERVAL = 2000

export const REPORTER_PHONE_NUMBER_COPIED_TEXT_INTERVAL = 2000

export const VALID_DATE_FOR_INACTIVE_ISSUES_FILTER = 14

export const ASSIGNED_ISSUES_POLL_INTERVAL = 100

export const BOOLEAN = {
  TRUE : true,
  FALSE: false
}

export const STRING = {
  TRUE : "true",
  FALSE: "false"
}

export const ENFORCE_MFA = {
  OPTIONAL: "optional",
  REQUIRED: "required"
}

export const ARRAY = {
  FIRST: 0
}

export const WIDGET = {
  AUTOCOMPLETE     : "autocomplete",
  TEXTAREA         : "textarea",
  RADIO_GROUP      : "radio group",
  DATE_TIME_PICKER : "date time picker",
  NUMBER_TEXT_FIELD: "number text field",
  TEXT_FIELD       : "text field",
  DATE_PICKER      : "date picker",
  COMBO_BOX        : "combo box",
  FIELD            : "FIELD"
}

export const NULL = null

export const ISSUE_TYPES = [{
  id  : 1,
  name: "Case"
}, {
  id  : 2,
  name: "Task"
}]

export const DATA_RETENTION_RULES = {
  GENERAL: ["Summary", "Description", "Issue documents", "Comments", "Issue history"],
  REPORT : ["Message content", "Report documents"],
  FIELD  : "Field"
}

export const AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES = {
  GENERAL: ["Summary", "Description", "Comments"],
  REPORT : ["Message content"],
  FIELD  : {
    TYPE: ["short text", "long text"]
  }
}

export const DATA_RETENTION_RULES_TO_DISPLAY_TOOLTIP = {
  MESSAGE_CONTENT: "Message content"
}

export const SCREEN_ITEM_DISPLAY_PROPERTIES = {
  resolutionId: {
    label                : "919",
    placeholder          : "921",
    computedPropertyValue: "resolutionsToDisplay"
  },
  dataRetainedUntil: {
    label                : "922",
    placeholder          : "923",
    computedPropertyValue: "retentionPeriodsToDisplay"
  },
  domainId: {
    label                : "292",
    placeholder          : "1260",
    computedPropertyValue: "activeDomainsToDisplay"
  },
  receivedAt: {
    label      : "639",
    placeholder: "1140"
  },
  dueDate: {
    label      : "1837",
    placeholder: "1140"
  },
  acknowledgedAt: {
    label      : "640",
    placeholder: "1140"
  },
  summary: {
    label             : "290",
    placeholderForCase: "1153",
    placeholderForTask: "1267"
  },
  description: {
    label             : "175",
    placeholderForCase: "1152",
    placeholderForTask: "952"
  },
  assigneeId: {
    label                : "946",
    computedPropertyValue: "usersWithUnassignedOption"
  },
  labels: {
    label                : "591",
    computedPropertyValue: "labelsForSelection"
  }
}

export const SCREEN_KEY_FIELD_TYPE = {
  summary          : "text field",
  acknowledgedAt   : "date time picker",
  receivedAt       : "date time picker",
  dueDate          : "date time picker",
  description      : "textarea",
  domainId         : "autocomplete",
  resolutionId     : "autocomplete",
  dataRetainedUntil: "autocomplete",
  assigneeId       : "autocomplete",
  labels           : "combo box"
}

export const DISALLOWED_SCREEN_ITEMS_FOR_CATEGORY_SUB = ["domainId", "receivedAt", "acknowledgedAt", "labels", "resolutionId", "dataRetainedUntil"]

export const TRANSITION_DIRECTIONS = {
  INCOMING: "incoming",
  OUTGOING: "outgoing"
}

export const DATA_RETENTION_OPTIONS = ["Keep", "Clear"]

export const AUTOMATION_ACTIONS = {
  DOMAIN_CHANGE: {
    value: "Domain change",
    label: "1412",
    icon : "mdi-arrow-right-thin"
  },
  SEND_EMAIL_NOTIFICATION: {
    value: "Send email notification",
    label: "1413",
    icon : "mdi-email-outline"
  } }

export const AUTOMATION_TRIGGERS = {
  SPEAK_UP_ISSUE_CREATE: {
    value: "Speak up issue create",
    label: "1414"
  }
}

export const ACTION_VALUE_CATEGORY = {
  USER : "user",
  GROUP: "group"
}

export const TABLE_NAMES = {
  REPORTER_FORM_TEMPLATES               : "REPORTER_FORM_TEMPLATES",
  OPTION_LISTS                          : "OPTION_LISTS",
  FIELDS                                : "FIELDS",
  FORM_TEMPLATE_FIELDS                  : "FORM_TEMPLATE_FIELDS",
  FORM_TEMPLATE_ADD_FIELDS              : "FORM_TEMPLATE_ADD_FIELDS",
  USERS                                 : "USERS",
  DOMAIN_CHANNELS                       : "DOMAIN_CHANNELS",
  GROUP_USERS                           : "GROUP_USERS",
  USERS_WITH_ACCESS                     : "USERS_WITH_ACCESS",
  GROUPS_WITH_ACCESS                    : "GROUPS_WITH_ACCESS",
  ISSUE_FORMS                           : "ISSUE_FORMS",
  ISSUE_FORM_FIELDS                     : "ISSUE_FORM_FIELDS",
  ISSUE_FORM_TEMPLATE_ADD_FIELDS        : "ISSUE_FORM_TEMPLATE_ADD_FIELDS",
  ISSUE_FORM_TEMPLATES                  : "ISSUE_FORM_TEMPLATES",
  ISSUE_ADD_CUSTOM_FIELDS               : "ISSUE_ADD_CUSTOM_FIELDS",
  ISSUE_FIELDS                          : "ISSUE_FIELDS",
  AUTOMATIONS                           : "AUTOMATIONS",
  EXCEPTIONS                            : "EXCEPTIONS",
  CHANNELS                              : "CHANNELS",
  GROUPS                                : "GROUPS",
  ROLES                                 : "ROLES",
  DOMAINS                               : "DOMAINS",
  LOGS                                  : "LOGS",
  ISSUES                                : "ISSUES",
  ISSUE_DOCUMENTS                       : "ISSUE_DOCUMENTS",
  WORKFLOWS                             : "WORKFLOWS",
  AI_PREFILL_FIELDS                     : "AI_PREFILL_FIELDS",
  TRANSITION_PRE_CONDITIONS             : "TRANSITION_PRE_CONDITIONS",
  TRANSITION_POST_FUNCTIONS             : "TRANSITION_POST_FUNCTIONS",
  SCREENS                               : "SCREENS",
  SCREEN_ADD_ITEMS                      : "SCREEN_ADD_ITEMS",
  SCREEN_ADD_FORM_ITEMS                 : "SCREEN_ADD_FORM_ITEMS",
  REPLY_TEMPLATES                       : "REPLY_TEMPLATES",
  CSV_DATA_VALIDATION                   : "CSV_DATA_VALIDATION",
  ROLE_PERMISSIONS                      : "ROLE_PERMISSIONS",
  ROLE_ADD_PERMISSIONS                  : "ROLE_ADD_PERMISSIONS",
  ISSUE_FORM_TEMPLATE_FOR_ISSUE_TYPE    : "ISSUE_FORM_TEMPLATE_FOR_ISSUE_TYPE",
  ISSUE_FORM_TEMPLATE_ADD_FOR_ISSUE_TYPE: "ISSUE_FORM_TEMPLATE_ADD_FOR_ISSUE_TYPE",
  FILTERS                               : "FILTERS"
}

export const POST_FUNCTIONS_NAMES = {
  "set field": {
    resolutionId: {
      value: null,
      label: "1139"
    },
    dataRetainedUntil: {
      value: null,
      label: "1147"
    }
  }
}

export const POST_FUNCTION_RULES = [{
  key      : "performAction",
  label    : "1462",
  mandatory: true,
  items    : [{
    text : "1458",
    value: "clear"
  }]
}, {
  key        : "field",
  label      : "1437",
  placeholder: "1463",
  mandatory  : true,
  items      : []
}]

export const POST_FUNCTION_FIELDS = {
  "set field": {
    resolutionId     : "1459",
    dataRetainedUntil: "1460"
  }
}

export const PRE_CONDITIONS_RULES = [{
  key      : "verify",
  label    : "1464",
  mandatory: true,
  items    : [{
    text : "1467",
    value: "report.status"
  }]
}, {
  key      : "checkOperator",
  label    : "1465",
  mandatory: true,
  items    : [{
    text : "1468",
    value: "equals"
  }]
}, {
  key      : "checkValue",
  label    : "1466",
  mandatory: true,
  items    : [{
    text : "1469",
    value: "closed"
  }]
}]

export const defaultTableHeaderClasses = "subheading grey--text grey lighten-4 subtitle-2 font-weight-bold"
export const defaultTableItemsPerPage = 50
export const defaultTableFooterOptions = {
  itemsPerPageOptions: [50, 100],
  itemsPerPageText   : "107",
  showFirstLastPage  : true,
  showCurrentPage    : true
}

export const POLICY_NAMES = {
  ISSUE_VIEW                      : "Issue view",
  REPORT_VIEW                     : "Report view",
  MESSAGE_VIEW                    : "Message view",
  MESSAGE_ITEM_VIEW               : "MessageItem view",
  MESSAGE_ITEM_MACHINE_TRANSLATE  : "MessageItem machine translate",
  MESSAGE_ITEM_REMOVE             : "MessageItem remove",
  MESSAGE_ADD                     : "Message add",
  REPORT_ADD_MESSAGES             : "Report add messages",
  MESSAGE_ITEM_ADD                : "MessageItem add",
  MESSAGE_ADD_ITEMS               : "Message add items",
  MESSAGE_ITEM_VIEW_UPLOAD_URL    : "MessageItem view upload url",
  MESSAGE_ITEM_VIEW_DOWNLOAD_URL  : "MessageItem view download url",
  MESSAGE_UPDATE                  : "Message update",
  MESSAGE_ITEM_UPDATE             : "MessageItem update",
  DOMAIN_VIEW                     : "Domain view",
  CHANNEL_VIEW                    : "Channel view",
  CHANNEL_VIEW_LOGO               : "Channel view logo",
  CHANNEL_ADD_LOGO                : "Channel add logo",
  CHANNEL_UPDATE                  : "Channel update",
  CONFIGURATION_VIEW              : "Configuration view",
  TRANSLATION_PREFERENCE_VIEW     : "TranslationPreference view",
  CONFIGURATION_UPDATE            : "Configuration update",
  USER_VIEW                       : "User view",
  USER_ADD                        : "User add",
  USER_UPDATE                     : "User update",
  GROUP_VIEW                      : "Group view",
  GROUP_ADD                       : "Group add",
  GROUP_UPDATE                    : "Group update",
  GROUP_REMOVE                    : "Group remove",
  GROUP_ADD_USERS                 : "Group add users",
  USER_ADD_GROUPS                 : "User add groups",
  GROUP_REMOVE_USERS              : "Group remove users",
  USER_REMOVE_GROUPS              : "User remove groups",
  DOMAIN_ADD                      : "Domain add",
  DOMAIN_UPDATE                   : "Domain update",
  CHANNEL_ADD                     : "Channel add",
  DOMAIN_ADD_CHANNELS             : "Domain add channels",
  POLICY_VIEW                     : "Policy view",
  ORGANISATION_CODE_VIEW          : "OrganisationCode view",
  ROLE_VIEW                       : "Role view",
  USER_ROLE_VIEW                  : "UserRole view",
  USER_ROLE_ADD                   : "UserRole add",
  USER_ADD_ROLES                  : "User add roles",
  ROLE_ADD_USERS                  : "Role add users",
  DOMAIN_ADD_USER_ROLES           : "Domain add user roles",
  GROUP_ROLE_VIEW                 : "GroupRole view",
  GROUP_ROLE_ADD                  : "GroupRole add",
  GROUP_ADD_ROLES                 : "Group add roles",
  ROLE_ADD_GROUPS                 : "Role add groups",
  DOMAIN_ADD_GROUP_ROLES          : "Domain add group roles",
  FORM_VIEW                       : "Form view",
  CHANNEL_VIEW_FORMS              : "Channel view forms",
  CHANNEL_ADD_FORMS               : "Channel add forms",
  CHANNEL_REMOVE_FORMS            : "Channel remove forms",
  TAXONOMY_VIEW                   : "Taxonomy view",
  ISSUE_TYPE_VIEW                 : "IssueType view",
  USER_VIEW_GROUPS                : "User view groups",
  ROLE_VIEW_POLICIES              : "Role view policies",
  REPORT_ADD                      : "Report add",
  CHANNEL_ADD_REPORTS             : "Channel add reports",
  REPORT_UPDATE                   : "Report update",
  REPORT_SEND_LOGIN               : "Report send login",
  ISSUE_STATUS_VIEW               : "IssueStatus view",
  ISSUE_RESOLUTION_VIEW           : "IssueResolution view",
  MESSAGE_REMOVE                  : "Message remove",
  REPORT_VIEW_FORM_FIELD_VALUES   : "Report view form field values",
  ISSUE_UPDATE                    : "Issue update",
  DOMAIN_ADD_ISSUES               : "Domain add issues",
  USER_ADD_ISSUES                 : "User add issues",
  REPORT_UPDATE_FORM_FIELD_VALUES : "Report update form field values",
  LANGUAGE_VIEW                   : "Language view",
  ACCESS_VIEW                     : "Access view",
  ACCESS_ADD                      : "Access add",
  DOMAIN_ADD_ROLES                : "Domain add roles",
  ISSUE_ADD_ROLES                 : "Issue add roles",
  TRANSLATION_UPDATE              : "Translation update",
  TRANSLATION_VIEW                : "Translation view",
  TRANSLATION_CONFIGURATION_UPDATE: "Channel update translation configuration",
  ROLE_ADD_ACCESSES               : "Role add access",
  ISSUE_ADD_ACCESSES              : "Issue add access",
  USER_SEND_PASSWORD              : "User send password",
  ACCESS_REMOVE                   : "Access remove",
  SUPPORT_ADD                     : "Support add",
  EXCEPTION_VIEW                  : "Exception view",
  EXCEPTION_UPDATE                : "Exception update",
  MESSAGE_ITEM_HUMAN_TRANSLATE    : "MessageItem human translate",
  TRANSLATION_ADD                 : "Translation add",
  MESSAGE_ITEM_ADD_TRANSLATIONS   : "MessageItem add translations",
  TRANSLATION_CONFIGURATION_VIEW  : "Channel view translation configuration",
  GROUP_VIEW_USERS                : "Group view users",
  USER_REMOVE                     : "User remove",
  LOG_VIEW                        : "Log view",
  ISSUE_VIEW_LOGS                 : "Issue view logs",
  ISSUE_ADD_COMMENT               : "Issue add comment",
  ISSUE_COMMENT_VIEW              : "IssueComment view",
  LABEL_VIEW                      : "Label view",
  ISSUE_COMMENT_REMOVE            : "IssueComment remove",
  FIELD_VIEW                      : "Field view",
  FORM_VIEW_FIELDS                : "Form view fields",
  CHANNEL_REMOVE_LOGO             : "Channel remove logo"
}

export const SSO_CONFIGURATIONS = {
  IDENTIFIER                      : "identifier",
  URN_AMAZON_COGNITO_SP           : "urn:amazon:cognito:sp:",
  AUTH                            : "auth",
  AMAZONCOGNITO_SAML_2_IDPRESPONSE: "amazoncognito.com/saml2/idpresponse"
}

export const MAX_CHARACTER_LIMIT = {
  REPORTER_FORM_TEMPLATE_NAME     : 128,
  OPTION_LIST_NAME                : 128,
  OPTION_LIST_ITEM_NAME           : 128,
  ISSUE_FORM_NAME                 : 128,
  FIELD_LABEL                     : 128,
  FIELD_SYSTEM_NAME               : 56,
  ISSUE_FORM_SHORT_TEXT_FIELD     : 128,
  ISSUE_FORM_LONG_TEXT_FIELD      : 50000,
  ISSUE_FIELDS_SHORT_TEXT_FIELD   : 128,
  ISSUE_SUMMARY                   : 128,
  ISSUE_DESCRIPTION_MAX_VALUE     : 50000,
  AUTOMATION_NAME                 : 128,
  PRIVACY_POLICY_DISPLAY_NAME     : 50,
  TRANSITION_NAME                 : 64,
  STATUS_NAME                     : 50,
  WORKFLOW_NAME                   : 128,
  SCREEN_NAME                     : 128,
  CHANNEL_DESCRIPTION             : 250,
  TRIAGE_DESCRIPTIONS             : 100,
  TERMS_OF_USE                    : 50000,
  SSO_VALIDATION_KEY              : 128,
  SSO_VALIDATION_VALUE            : 128,
  PRIVACY_POLICY_TEXT             : 90000,
  REPLY_TEMPLATE_NAME             : 128,
  REPLY_TEMPLATE_CONTENT          : 50000,
  PRIVACY_POLICY_TEXT_TRANSLATIONS: 120000,
  FOLDER_NAME                     : 50,
  FILE_DESCRIPTION                : 300,
  ROLE_NAME                       : 50,
  ROLE_DESCRIPTION                : 128,
  FILTER_NAME                     : 128,
  INSIGHTS_DATE_RANGE             : 365,
  CUSTOM_VIEW_NAME                : 50,
  CHART_NAME                      : 50
}

export const QUERY_OPERAND = {
  OR: "OR"
}

export const FIELD_TYPES = {
  DATE: {
    value : "date",
    name  : "999",
    icon  : "mdi-calendar-outline",
    widget: WIDGET.DATE_PICKER
  },
  DATE_TIME: {
    value : "date time",
    name  : "1214",
    icon  : "mdi-calendar-clock-outline",
    widget: WIDGET.DATE_TIME_PICKER
  },
  NUMBER: {
    value : "number",
    name  : "682",
    icon  : "mdi-numeric-1-box",
    widget: WIDGET.NUMBER_TEXT_FIELD
  },
  OPTION_LIST: {
    value : "option list",
    name  : "817",
    icon  : "mdi-format-list-bulleted",
    widget: WIDGET.AUTOCOMPLETE
  },
  MULTIPLE_OPTION_LIST: {
    value : "multiple option list",
    name  : "1072",
    icon  : "mdi-format-list-bulleted",
    widget: WIDGET.AUTOCOMPLETE
  },
  LONG_TEXT: {
    value : "long text",
    name  : "962",
    icon  : "mdi-text-long",
    widget: WIDGET.TEXTAREA
  },
  SHORT_TEXT: {
    value : "short text",
    name  : "797",
    icon  : "mdi-text-short",
    widget: WIDGET.TEXT_FIELD
  },
  BOOLEAN: {
    value : "boolean",
    name  : "795",
    icon  : "mdi-circle-half-full",
    widget: WIDGET.RADIO_GROUP
  }
}

export const STATUS_CATEGORY_CLASS = {
  "New"        : "info lighten-4 primary--text",
  "In progress": "light-blue lighten-4 blue--text text--accent-4",
  "Done"       : "success darken1 white--text"
}

export const STATUS_KEYS = {
  "domainId"         : "1501",
  "summary"          : "1502",
  "description"      : "1503",
  "receivedAt"       : "1504",
  "acknowledgedAt"   : "1505",
  "assigneeId"       : "1506",
  "labels"           : "1507",
  "resolutionId"     : "1508",
  "dataRetainedUntil": "1509",
  "dueDate"          : "1836"
}

export const PERMISSION_NAME_TO_DISPLAY_NAME_MAP = {
  "view-issue"                            : "1978",
  "edit-issue-domain"                     : "1979",
  "edit-issue-status-resolution-retention": "1980",
  "edit-issue-properties"                 : "1981",
  "view-issue-fields"                     : "1982",
  "edit-issue-fields"                     : "1983",
  "view-report-overview"                  : "1984",
  "view-report-messages"                  : "1985",
  "view-reporter-intake-form"             : "1986",
  "reply-to-reporter"                     : "1987",
  "end-communication-with-reporter"       : "1988",
  "request-human-translation"             : "1989",
  "view-issue-documents"                  : "1990",
  "add-issue-documents-and-folders"       : "1991",
  "remove-own-issue-documents"            : "1992",
  "remove-all-issue-documents"            : "1993",
  "remove-documents-folder"               : "1994",
  "view-linked-issues"                    : "1995",
  "add-linked-issues"                     : "1996",
  "remove-linked-issues"                  : "1997",
  "view-issue-comments"                   : "1998",
  "add-issue-comments"                    : "1999",
  "remove-own-issue-comments"             : "2000",
  "remove-all-issue-comments"             : "2001",
  "view-issue-forms"                      : "2002",
  "add-issue-forms"                       : "2003",
  "edit-issue-form"                       : "2004",
  "remove-issue-form"                     : "2005",
  "view-issue-access"                     : "2006",
  "add-issue-access"                      : "2007",
  "remove-issue-access"                   : "2008",
  "view-issue-history"                    : "2009",
  "add-sub-issue"                         : "2010",
  "extract-issue-as-pdf"                  : "2011",
  "view-analytics-insights"               : "2012",
  "download-analytics-export"             : "2013",
  "download-issues-overview"              : "2014",
  "add-standard-issue"                    : "2015",
  "import-issues"                         : "2016",
  "create-and-customise-filters"          : "2222",
  "create-and-customise-views"            : "2228"
}

export const ALLOWED_TYPES_FOR_ISSUE_FIELDS = [
  FIELD_TYPES.OPTION_LIST.value,
  FIELD_TYPES.SHORT_TEXT.value,
  FIELD_TYPES.DATE_TIME.value,
  FIELD_TYPES.BOOLEAN.value,
  FIELD_TYPES.NUMBER.value,
  FIELD_TYPES.DATE.value,
  FIELD_TYPES.MULTIPLE_OPTION_LIST.value
]

export const ALLOWED_TYPES_FOR_REPORTER_INTAKE_FORMS = [
  FIELD_TYPES.OPTION_LIST.value,
  FIELD_TYPES.SHORT_TEXT.value,
  FIELD_TYPES.BOOLEAN.value
]

export const GET_CHANNEL_URL = (clientName, channelName) =>
  format(process.env.VUE_APP_REPORTER_URL, clientName, channelName)

export const ISSUES_ROUTES = ["filters", "issues", "filter"]
export const ACCESS_MANAGEMENT_ROUTES = ["users", "groups", "roles", "domains", "channels", "user", "group", "domain", "domain-channel", "channel", "user-add"]
export const FORMS_ROUTES = ["option-lists", "fields", "reporter-intake-forms", "issue-forms", "option-list", "field", "reporter-intake-form", "issue-form", "issue-form-field-configuration", "reporter-intake-form-template-configuration"]
export const ISSUE_CUSTOMISATION_ROUTES = ["issue-fields", "issue-field", "issue-form-templates", "issue-form-template", "workflows", "workflow", "workflow-status-side-panel", "transition", "screens", "screen", "screen-item-side-panel", "screen-item-side-panel-with-form"]
export const SYSTEM_ROUTES = ["configurations", "automations", "automation-add", "automation-edit", "export", "exceptions", "logs", "reply-templates"]

export const OPTION_LIST_TRANSLATION_FILE_UPLOAD_POLL_INTERVAL = 20000
export const OPTION_LIST_EXPORT_POLL_INTERVAL = 20000
export const DEFAULT_ISSUES_COLUMNS = ["summary", "domain", "status", "assignee", "labels", "lastUpdated"]

export const DEFAULT_DATA_RETENTION_PERIODS = [30, 182, 365, 730, 1825, 2557, 3652]

export const DATA_RETENTION_STATUS = {
  SCHEDULED    : "scheduled",
  NOT_SCHEDULED: null,
  ANONYMISED   : "anonymised"
}

export const NOTIFICATIONS = {
  NEW_REPORT                               : 4,
  NEW_MESSAGE                              : 5,
  ISSUE_INVITATION                         : 6,
  ISSUE_INVITATION_REMOVED                 : 7,
  ISSUE_ASSIGNED                           : 8,
  NEW_ISSUE_CREATED                        : 9,
  REDO_TRANSLATION_COMPLETED               : 11,
  NEW_COMMENT                              : 13,
  HUMAN_TRANSLATION_JOB_DONE               : 19,
  DATA_EXPORT_CREATED                      : 20,
  MENTION_COMMENTS                         : 24,
  ACCESS_CHANGE                            : 25,
  CONFIGURATION_CHANGE                     : 26,
  ACCESS_TO_ISSUE_THROUGH_DOMAIN_CHANGE    : 27,
  DUE_DATE_REMINDER_DUE_SOON_ASSIGNEE      : 28,
  DUE_DATE_REMINDER_OVERDUE_ASSIGNEE       : 29,
  DUE_DATE_REMINDER_DUE_SOON_EDITING_RIGHTS: 30,
  DUE_DATE_REMINDER_OVERDUE_EDITING_RIGHTS : 31
}

export const DEFAULT_USER_EMAIL_NOTIFICATIONS = {
  REPORT: [
    NOTIFICATIONS.NEW_REPORT,
    NOTIFICATIONS.NEW_MESSAGE,
    NOTIFICATIONS.REDO_TRANSLATION_COMPLETED,
    NOTIFICATIONS.HUMAN_TRANSLATION_JOB_DONE
  ],
  ISSUE: [
    NOTIFICATIONS.NEW_ISSUE_CREATED,
    NOTIFICATIONS.ACCESS_TO_ISSUE_THROUGH_DOMAIN_CHANGE,
    NOTIFICATIONS.ISSUE_INVITATION,
    NOTIFICATIONS.ISSUE_INVITATION_REMOVED,
    NOTIFICATIONS.ISSUE_ASSIGNED,
    NOTIFICATIONS.NEW_COMMENT,
    NOTIFICATIONS.MENTION_COMMENTS,
    NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_ASSIGNEE,
    NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_EDITING_RIGHTS
  ],
  SYSTEM_WIDE: [
    NOTIFICATIONS.DATA_EXPORT_CREATED,
    NOTIFICATIONS.ACCESS_CHANGE,
    NOTIFICATIONS.CONFIGURATION_CHANGE
  ]
}

export const ORDER_OF_ISSUE_DEFAULT_ROLES = [2, 3, 4]

export const ANCHOR_LINKS = {
  REPORT_INTAKE                 : "#report-intake",
  CUSTOMISATION                 : "#customisation",
  PRIVACY_POLICY                : "#privacy-policy",
  TRANSLATION_AND_LANGUAGE      : "#translation-and-language",
  GENERAL                       : "#general",
  ACCESS                        : "#access",
  SECURITY                      : "#security",
  DATA_RETENTION                : "#data-retention",
  CHANNELS                      : "#channels",
  DOMAIN_ACCESS                 : "#domain-access",
  PASSWORD                      : "#password",
  NOTIFICATIONS                 : "#notifications",
  SHARE_POINT_INTEGRATION       : "#share-point-integration",
  ALL_DATA_EXPORT               : "#all-data-export",
  PRE_CONDITIONS                : "#pre-conditions",
  POST_FUNCTIONS                : "#post-functions",
  TERMS_OF_USE                  : "#terms-of-use",
  EDIT                          : "#edit",
  LINK_DISPLAY_NAME_TRANSLATIONS: "#customisation_link-display-name-translations"
}

export const PAGES = {
  CHANNEL: [
    ANCHOR_LINKS.REPORT_INTAKE,
    ANCHOR_LINKS.CUSTOMISATION,
    ANCHOR_LINKS.PRIVACY_POLICY,
    ANCHOR_LINKS.TRANSLATION_AND_LANGUAGE
  ],
  CONFIGURATIONS: [
    ANCHOR_LINKS.GENERAL,
    ANCHOR_LINKS.ACCESS,
    ANCHOR_LINKS.SECURITY,
    ANCHOR_LINKS.DATA_RETENTION,
    ANCHOR_LINKS.TERMS_OF_USE
  ],
  USER_SETTINGS: [ANCHOR_LINKS.PASSWORD, ANCHOR_LINKS.NOTIFICATIONS],
  EXPORTS      : [ANCHOR_LINKS.SHARE_POINT_INTEGRATION, ANCHOR_LINKS.ALL_DATA_EXPORT],
  DOMAIN       : [ANCHOR_LINKS.CHANNELS, ANCHOR_LINKS.DOMAIN_ACCESS],
  TRANSITION   : [ANCHOR_LINKS.PRE_CONDITIONS, ANCHOR_LINKS.POST_FUNCTIONS]
}

export const WORKFLOW_ROUTES = ["workflow", "workflow-status-side-panel", "transition"]

export const AWS_REGION_ID = {
  UAE      : "me-central-1",
  FRANKFURT: "eu-central-1"
}

export const REPORT_CONTENTS_TO_BE_CLEARED = [{
  label  : "1589",
  value  : "messageContent",
  checked: false
}, {
  label  : "1590",
  value  : "reportDocument",
  checked: false
}]

export const HIGHLIGHTED_ISSUES_WIDGET = {
  ISSUE_UNREAD_MESSAGES: {
    label: "2104"
  },
  ISSUE_UNANSWERED_MESSAGES: {
    label: "2105"
  },
  ISSUE_DUE_SOON: {
    label: "2135"
  },
  ISSUE_OVERDUE: {
    label: "2172"
  },
  ISSUE_INACTIVE: {
    label: "2217"
  }
}

export const SHARE_ACCESS = {
  EVERYONE: "everyone",
  PRIVATE : "private",
  GROUPS  : "groups"
}

export const MESSAGE_VIEW_WIDGETS = [
  "ISSUE_UNREAD_MESSAGES",
  "ISSUE_UNANSWERED_MESSAGES"
]

export const ISSUE_VIEW_DUE_DATE_WIDGETS = [
  "ISSUE_DUE_SOON",
  "ISSUE_OVERDUE"
]

export const ISSUE_VIEW_UPDATED_AT_WIDGETS = [
  "ISSUE_INACTIVE"
]

export const WIDGET_CLASS = {
  HOVER_LABEL       : "font-weight-bold",
  HOVER_ICON        : "black--text",
  INDICATOR_ACTIVE  : "secondary--text",
  INDICATOR_DISABLED: "grey--text"
}

export const QUERY_MAP = {
  "ISSUE_UNREAD_MESSAGES"    : { unread: true },
  "ISSUE_UNANSWERED_MESSAGES": { unanswered: true },
  "ISSUE_OVERDUE"            : { overdue: true },
  "ISSUE_DUE_SOON"           : { dueSoon: true },
  "ISSUE_INACTIVE"           : { inactive: true }
}
export const DASHBOARD_WIDGET_FILTER_PREFERENCE = {
  UNREAD_MESSAGES: {
    messageStatus: ["unread"]
  },
  UNANSWERED_MESSAGES: {
    initialResponseStatus: ["not responded"]
  }
}

export const CHANNEL_TRIAGE_DEFAULT_DESCRIPTIONS = {
  SPEAKUP    : "Click here to leave a report regarding any misconduct or breach against our code of conduct.",
  ALTERNATIVE: "Click here to leave a product or service complaint. This will be submitted outside of SpeakUp."
}

export const LANGUAGE = {
  CLIENT_DEFAULT: {
    id       : 18,
    name     : "English",
    shortName: "en-GB"
  },
  DUTCH: {
    id       : 16,
    name     : "Dutch",
    shortName: "nl-NL"
  }
}

export const REPORT_SOURCE_FOR_AUTOMATION = {
  WEB_OR_APP: "web/app",
  PHONE     : "phone"
}

export const AUTOMATION_FIELD_FOR_SOURCE_PHONE = ["Country of report"]

export const NO_DATA_FOUND = "No data found"
export const NONE = "None"
export const NO_MESSAGE_FOUND = "No message found"
export const TYPE = {
  AI_PREFILL_FIELDS: "AI Prefill Fields",
  AI_SUMMARISATION : "AI Summarisation"
}

export const AI_PREFILL_FEEDBACK_QUESTIONS = {
  FIRST : "Are you satisfied with the results?",
  SECOND: "What was the problem?",
  THIRD : "Please explain the problem in more detail"
}

export const AI_PREFILL_FEEDBACK_ANSWERS = {
  YES          : "Yes",
  NO           : "No",
  FIRST_OPTION : "Suggestions were misleading",
  SECOND_OPTION: "Not enough fields were filled",
  THIRD_OPTION : "Other"
}

export const AI_SUMMARISATION_FEEDBACK_QUESTIONS = {
  FIRST : "Are you satisfied with the results?",
  SECOND: "What was your experience?",
  THIRD : "Please explain the situation in more detail"
}

export const AI_SUMMARISATION_FEEDBACK_ANSWERS = {
  YES          : "Yes",
  NO           : "No",
  FIRST_OPTION : "Summary was misleading",
  SECOND_OPTION: "Summary was missing key details",
  THIRD_OPTION : "Other"
}

export const BOOLEAN_OPTIONS = [{
  name: "No"
}, {
  name: "Yes"
}]

export const MILLISECONDS_IN_A_SECOND = 1000

export const MILLISECONDS_IN_A_MINUTE = 60000

export const SECONDS_IN_A_MINUTE = 60

export const ISSUE_PROPERTIES = {
  CREATED_AT   : "createdAt",
  STATUS_ID    : "statusId",
  RESOLUTION_ID: "resolutionId",
  SUMMARY      : "summary",
  DESCRIPTION  : "description"
}

export const KEYS_FOR_BULK_IMPORT = {
  ISSUE_FIELD: "issueField",
  ISSUE_FORM : "issueForm"
}

export const FILE_TYPE = {
  FOLDER         : "folder",
  DOCUMENT       : "document",
  REPORT_DOCUMENT: "reportDocument"
}

export const SPEAK_UP_FIELDS_ITEMS = {
  SKIP_IN_IMPORT: "skipInImport",
  RESOLUTION_ID : "resolutionId",
  STATUS_ID     : "statusId",
  CREATED_AT    : "createdAt",
  DESCRIPTION   : "description",
  SUMMARY       : "summary"
}

export const SPEAK_UP_USERS_FIELDS_ITEMS = {
  SKIP_IN_IMPORT: "skipInImport",
  NAME          : "name",
  PHONE         : "phone",
  EMAIL         : "email",
  CREATED_AT    : "createdAt",
  JOB_TITLE     : "jobTitle",
  GROUP         : "groupId"
}
export const BULK_IMPORT_ISSUE_PROPERTIES_ARRAY = ["createdAt", "statusId", "summary", "description", "resolutionId"]
export const DEPENDENCY_CONDITIONS = {
  AND: "AND",
  OR : "OR"
}

export const TABS = {
  ISSUE_FIELDS: "Issue Fields",
  ISSUE_FORMS : "Issue Forms",
  VIEWS       : "Views",
  EXPORT      : "Export"
}

export const ROUTE_NAME = {
  ISSUE_FIELDS        : "issue-fields",
  ISSUE_FIELD         : "issue-field",
  ISSUE_FORM_TEMPLATES: "issue-form-templates",
  ISSUE_FORM_TEMPLATE : "issue-form-template",
  ANALYTICS_VIEWS     : "analytics-views",
  ANALYTICS_VIEW      : "analytics-view",
  ANALYTICS_EXPORT    : "analytics-export"
}

export const QUICK_LINKS_CONFIGURATION = [{
  displayLocale: "2231",
  from         : {
    month: 1,
    day  : 1
  },
  to: {
    month: 3,
    day  : 31
  }
}, {
  displayLocale: "2232",
  from         : {
    month: 4,
    day  : 1
  },
  to: {
    month: 6,
    day  : 30
  }
}, {
  displayLocale: "2233",
  from         : {
    month: 7,
    day  : 1
  },
  to: {
    month: 9,
    day  : 30
  }
}, {
  displayLocale: "2234",
  from         : {
    month: 10,
    day  : 1
  },
  to: {
    month: 12,
    day  : 31
  }
}, {
  displayLocale: "2235",
  from         : {
    month: 1,
    day  : 1
  },
  to: {
    month: 6,
    day  : 30
  }
}, {
  displayLocale: "2236",
  from         : {
    month: 7,
    day  : 1
  },
  to: {
    month: 12,
    day  : 31
  }
}, {
  displayLocale: "2237",
  from         : {
    month     : 1,
    day       : 1,
    yearOffset: -1
  },
  to: {
    month     : 12,
    day       : 31,
    yearOffset: -1
  }
}, {
  displayLocale: "2238",
  from         : {
    month: 1,
    day  : 1
  },
  to: {
    month: new Date().getMonth() + 1,
    day  : new Date().getDate()
  }
}]

export const SCREEN_ITEM_TYPE = {
  FIELD         : "FIELD",
  FORM          : "FORM",
  ISSUE_PROPERTY: "ISSUE_PROPERTY"
}
export const ALLOWED_TYPES_FOR_ISSUE_FORMS_IN_COLUMNS = [
  FIELD_TYPES.BOOLEAN.value,
  FIELD_TYPES.MULTIPLE_OPTION_LIST.value,
  FIELD_TYPES.OPTION_LIST.value,
  FIELD_TYPES.NUMBER.value,
  FIELD_TYPES.DATE.value,
  FIELD_TYPES.DATE_TIME.value
]

export const ALLOWED_TYPES_FOR_REPORTER_FORMS_IN_COLUMNS = [
  FIELD_TYPES.BOOLEAN.value,
  FIELD_TYPES.OPTION_LIST.value
]

export const CHART_TYPES = {
  BAR_VERTICAL  : "bar vertical",
  BAR_HORIZONTAL: "bar horizontal",
  LINE          : "line",
  DOUGHNUT      : "doughnut"
}

export const CHART_TYPES_IN_LIBRARY = {
  BAR     : "bar",
  LINE    : "line",
  DOUGHNUT: "donut"
}

export const METRICS = {
  ISSUE_COUNT : "issue count",
  REPORT_COUNT: "report count"
}

export const TIME_FRAMES = {
  day  : "day",
  month: "month",
  year : "year"
}

export const CHART_TYPES_FOR_DISPLAY =  [{
  value: CHART_TYPES.BAR_VERTICAL,
  text : "2306",
  icon : "mdi-chart-bar"
}, {
  value: CHART_TYPES.BAR_HORIZONTAL,
  text : "2307",
  icon : "mdi-chart-bar",
  class: "rotate-by-90"
}, {
  value: CHART_TYPES.LINE,
  text : "2383",
  icon : "mdi-chart-line-variant"
}, {
  value: CHART_TYPES.DOUGHNUT,
  text : "2435",
  icon : "mdi-chart-donut"
}]

export const METRICS_FOR_DISPLAY = [{
  value: METRICS.ISSUE_COUNT,
  text : "2310"
}, {
  value: METRICS.REPORT_COUNT,
  text : "2311"
}]

export const TIME_FRAMES_FOR_DISPLAY = [{
  value: TIME_FRAMES.day,
  text : "2442"
}, {
  value: TIME_FRAMES.month,
  text : "2443"
}, {
  value: TIME_FRAMES.year,
  text : "2444"
}]

export const ALLOWED_FIELD_TYPES_FOR_CHART_DIMENSIONS_AND_SUBDIVISIONS = [
  FIELD_TYPES.BOOLEAN.value,
  FIELD_TYPES.OPTION_LIST.value,
  FIELD_TYPES.MULTIPLE_OPTION_LIST.value
]

export const ISSUE_PROPERTIES_FOR_CHART = {
  ASSIGNEE         : "assignee",
  CHANNELS         : "channels",
  DATE             : "date",
  DOMAINS          : "domains",
  REPORT_LANGUAGE  : "reportLanguage",
  SOURCE           : "source",
  STATUS           : "status",
  MANUAL_OR_SPEAKUP: "manualOrSpeakUp"
}

export const CHART_DIMENSION_TYPES = {
  PROPERTY  : "property",
  FIELD     : "field",
  FORM_FIELD: "form_field"
}

export const MAXIMUM_CHARTS_PER_CUSTOM_VIEW = 6
export const VERTICAL_CHART_HEIGHT = 500

export const DEFAULT_CHARTS = [{
  dimension: {
    type    : CHART_DIMENSION_TYPES.PROPERTY,
    selected: ISSUE_PROPERTIES_FOR_CHART.DATE
  },
  metric            : METRICS.ISSUE_COUNT,
  name              : "1400",
  id                : "total_cases_chart",
  type              : CHART_TYPES.BAR_VERTICAL,
  chartTypeInLibrary: CHART_TYPES_IN_LIBRARY.BAR,
  savedFilterId     : null,
  subdivision       : {
    type    : CHART_DIMENSION_TYPES.PROPERTY,
    selected: ISSUE_PROPERTIES_FOR_CHART.MANUAL_OR_SPEAKUP
  }
}, {
  dimension: {
    type    : CHART_DIMENSION_TYPES.PROPERTY,
    selected: ISSUE_PROPERTIES_FOR_CHART.DATE
  },
  metric            : METRICS.ISSUE_COUNT,
  name              : "1401",
  id                : "total_cases_per_domain_chart",
  type              : CHART_TYPES.BAR_VERTICAL,
  chartTypeInLibrary: CHART_TYPES_IN_LIBRARY.BAR,
  savedFilterId     : null,
  subdivision       : {
    type    : CHART_DIMENSION_TYPES.PROPERTY,
    selected: ISSUE_PROPERTIES_FOR_CHART.DOMAINS
  }
}, {
  dimension: {
    type    : CHART_DIMENSION_TYPES.PROPERTY,
    selected: ISSUE_PROPERTIES_FOR_CHART.CHANNELS
  },
  metric            : METRICS.REPORT_COUNT,
  name              : "1402",
  id                : "total_reports_per_channel_chart",
  type              : CHART_TYPES.BAR_HORIZONTAL,
  chartTypeInLibrary: CHART_TYPES_IN_LIBRARY.BAR,
  savedFilterId     : null,
  subdivision       : null
}]

export const SUBDIVISION_LABEL_OVERRIDES = {
  [ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE]: {
    default: "2138"
  },
  [ISSUE_PROPERTIES_FOR_CHART.CHANNELS]: {
    [METRICS.ISSUE_COUNT]: "2139",
    default              : "2140"
  },
  [ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE]: {
    [METRICS.ISSUE_COUNT]: "2139",
    default              : "2140"
  },
  [ISSUE_PROPERTIES_FOR_CHART.SOURCE]: {
    [METRICS.ISSUE_COUNT]: "2139",
    default              : "2140"
  }
}

export const ISSUE_EXPORT_FORMAT = {
  PDF : "pdf",
  DOCX: "docx"
}

export const ISSUE_EXPORT_BACKGROUND_COLOR = "#F5F5F5"
export const ISSUE_EXPORT_TEXT_COLOR = "#006646"

export default {
  APPLICATION_ID      : 20002,
  SAVED_TIMEOUT       : 5000,
  NOTIFICATION_TIMEOUT: 5000,
  MILLISECONDS_IN_A_SECOND,
  MILLISECONDS_IN_A_MINUTE,
  SECONDS_IN_A_MINUTE,
  ISSUE_ROLES,
  LANGUAGE,
  ISSUE_TYPES,
  ALLOWED_TYPES_FOR_ISSUE_FIELDS,
  ALLOWED_TYPES_FOR_REPORTER_INTAKE_FORMS,
  WIDGET,
  ISSUE_EXPORT_FORMAT,
  ISSUE_SEARCH_FILTERS: {
    ID                     : ["ID", "id"],
    SOURCE                 : ["Source", "report[0].source"],
    DOMAIN                 : ["Domain", "domainId"],
    ASSIGNEE               : ["Assignee", "assigneeId"],
    STATUS                 : ["Status", "statusId"],
    TYPE                   : ["Type", "typeId"],
    RESOLUTION             : ["Resolution", "resolutionId"],
    DATE_RANGE             : ["Created on", "createdAt"],
    UPDATED_AT             : ["Last Updated", "updatedAt"],
    CHANNEL                : ["Channel", "report[0].channelId"],
    REPORT_STATUS          : ["Report status", "report[0].status"],
    ISSUE_ANONYMISATION    : ["Anonymised issue", "dataRetentionStatus"],
    LABEL                  : ["Label", "labels"],
    FORM_TEMPLATE          : "report[0].formInstances[0].formInstanceFields",
    ISSUE_FORM             : "formInstances[0].formInstanceFields",
    LANGUAGE               : ["Reporter language", "report[0].languageId"],
    ISSUE_FIELD            : "issueFieldValues",
    DUE_DATE               : ["Due date", "dueDate"],
    MESSAGE_STATUS         : ["Message status", "messageStatus"],
    INITIAL_RESPONSE_STATUS: ["Initial response status", "initialResponseStatus"],
    ISSUE_STATUS_CATEGORY  : ["Status Category", "status[0].category"]
  },
  ROLE_ASSIGNMENT_CATEGORY,
  HTTP_STATUS_CODE,
  ISSUE_STATUS,
  NOTIFICATION_TYPE,
  SPECIAL_CHARACTERS,
  REPORT_STATUS,
  MESSAGE_STATUS,
  TRANSLATION_PREFERENCES,
  COOKIE_OPTIONS,
  MFA,
  COGNITO_AUTHENTICATION_MESSAGE,
  ERROR,
  EXCEPTION_STATUS,
  REPORT_SOURCE,
  DATE: {
    MILLISECONDS_IN_ONE_DAY: 1000 * 60 * 60 * 24
  },
  DAYS_WITHIN_EXCEPTION_TO_BE_APPROVED: 7,
  SPEAKUP_ISSUE_ACKNOWLEDGEMENT,
  CONFIGURATIONS,
  ISSUE_DOCUMENTS_TYPES,
  IMPORT_CASES_DOCUMENT_TYPE,
  IMPORT_USERS_DOCUMENT_TYPE,
  REPORT_ANONYMISATION_STATUS,
  FILE_NAME,
  ITEM_COMPARISON,
  DATA_EXPORT_TYPE,
  BOOLEAN,
  NULL,
  IDP_LOGIN_URL,
  FORMS,
  FIELD_TYPES,
  DEFAULT_ISSUES_COLUMNS,
  INTEGER,
  DEFAULT_DATA_RETENTION_PERIODS,
  DATA_RETENTION_STATUS,
  ANCHOR_LINKS,
  PAGES,
  STATUS_KEYS,
  NO_DATA_FOUND,
  NONE,
  REPORT_SOURCE_FOR_AUTOMATION,
  AI_PREFILL_FEEDBACK_QUESTIONS,
  AI_PREFILL_FEEDBACK_ANSWERS,
  ISSUE_PROPERTIES,
  KEYS_FOR_BULK_IMPORT,
  SPEAK_UP_FIELDS_ITEMS,
  SPEAK_UP_USERS_FIELDS_ITEMS,
  BULK_IMPORT_ISSUE_PROPERTIES_ARRAY,
  BULK_IMPORT_TYPES,
  FILE_TYPE,
  PERMISSION_NAME_TO_DISPLAY_NAME_MAP,
  MILLISECONDS_TO_SHOW_LOGOUT_NOTIFICATION,
  MESSAGE_FILTER_STATUS,
  ISSUE_STATUS_CATEGORY,
  INITIAL_RESPONSE_STATUS,
  DEPENDENCY_CONDITIONS,
  AI_ASSISTANCE_STATUS,
  AI_SUMMARISATION_FEEDBACK_QUESTIONS,
  AI_SUMMARISATION_FEEDBACK_ANSWERS,
  VALID_DATE_FOR_INACTIVE_ISSUES_FILTER,
  ROUTE_NAME,
  TABS,
  QUICK_LINKS_CONFIGURATION,
  SCREEN_ITEM_TYPE,
  ALLOWED_TYPES_FOR_ISSUE_FORMS_IN_COLUMNS,
  ALLOWED_TYPES_FOR_REPORTER_FORMS_IN_COLUMNS,
  CHART_TYPES,
  METRICS,
  ARRAY,
  ISSUE_EXPORT_BACKGROUND_COLOR,
  ISSUE_EXPORT_TEXT_COLOR
}
