import ThemisInput from "@/components/shared/input"
import { WIDGET } from "@/constants"

export default {
  name      : "IssueExport",
  components: {
    ThemisInput
  },
  props: {
    pIssueData              : Object,
    pIsIssueExportInProgress: Boolean
  },
  emits: ["startExport", "cancel"],
  data() {
    return {
      exportOptions: {
        reportOverview      : false,
        reporterIntakeFields: false,
        messages            : false,
        issueFields         : false,
        issueForms          : false,
        selectedIssueForms  : [],
        linkedIssues        : false,
        tasks               : false,
        comments            : false,
        history             : false
      },
      exportFormat: null
    }
  },
  computed: {
    isAnyExportOptionSelected() {
      const { issueForms, selectedIssueForms, ...otherOptions } = this.exportOptions
      const otherOptionsSelected                                = Object.values(otherOptions).some(value => value)
      if (issueForms) {
        return selectedIssueForms.length > 0
      }

      return otherOptionsSelected
    },
    isStartExportButtonDisabled() {
      return !(this.exportFormat && this.isAnyExportOptionSelected)
    },
    exportDisplayOptions() {
      return [{
        name    : this.$t("2387"),
        show    : this.pIssueData.reportOverview.show,
        disable : this.pIssueData.reportOverview.disable || this.pIsIssueExportInProgress,
        property: "reportOverview",
        dataCy  : "8020",
        ref     : "checkbox_report_overview"
      }, {
        name    : this.$t("2388"),
        show    : this.pIssueData.reportIntakeForms.show,
        disable : this.pIssueData.reportIntakeForms.disable || this.pIsIssueExportInProgress,
        dataCy  : "8021",
        ref     : "checkbox_reporter_intake_fields",
        property: "reporterIntakeFields"
      }, {
        name    : this.$t("2389"),
        show    : this.pIssueData.messages.show,
        disable : this.pIssueData.messages.disable || this.pIsIssueExportInProgress,
        dataCy  : "8022",
        ref     : "checkbox_messages",
        property: "messages"
      }, {
        name    : this.$t("2390"),
        show    : this.pIssueData.issueCustomFields.show,
        disable : this.pIssueData.issueCustomFields.disable || this.pIsIssueExportInProgress,
        dataCy  : "8023",
        ref     : "checkbox_issue_fields",
        property: "issueFields"
      }, {
        name    : this.$t("2391"),
        show    : this.pIssueData.issueForms.show,
        disable : this.pIssueData.issueForms.disable || this.pIsIssueExportInProgress,
        dataCy  : "8024",
        ref     : "checkbox_issue_forms",
        property: "issueForms",
        children: [{
          show    : this.exportOptions.issueForms,
          disable : this.pIssueData.issueForms.disable || this.pIsIssueExportInProgress,
          type    : "autocomplete",
          multiple: true,
          name    : this.$t("2392"),
          dataCy  : "8025",
          ref     : "autocomplete_issue_forms",
          property: "selectedIssueForms",
          values  : this.pIssueData.issueForms.data
        }]
      }, {
        name    : this.$t("2393"),
        show    : this.pIssueData.linkedIssues.show,
        disable : this.pIssueData.linkedIssues.disable || this.pIsIssueExportInProgress,
        dataCy  : "8026",
        ref     : "checkbox_linked_issues",
        property: "linkedIssues"
      }, {
        name    : this.$t("2394"),
        show    : this.pIssueData.tasks.show,
        disable : this.pIssueData.tasks.disable,
        dataCy  : "8027",
        ref     : "checkbox_tasks",
        property: "tasks"
      }, {
        name    : this.$t("2395"),
        show    : this.pIssueData.comments.show,
        disable : this.pIssueData.comments.disable || this.pIsIssueExportInProgress,
        dataCy  : "8028",
        ref     : "checkbox_comments",
        property: "comments"
      }, {
        name    : this.$t("2396"),
        show    : this.pIssueData.history.show,
        disable : this.pIssueData.history.disable || this.pIsIssueExportInProgress,
        dataCy  : "8029",
        ref     : "checkbox_history",
        property: "history"
      }]
    }
  },
  methods: {
    startExport() {
      this.$emit("startExport", {
        exportOptions: this.exportOptions,
        exportFormat : this.exportFormat
      })
    },
    handleCancel() {
      this.$emit("cancel")
    },
    shouldShowAutocomplete(child) {
      return child.show && child.type === WIDGET.AUTOCOMPLETE
    }
  },
  watch: {
    "exportOptions.issueForms": {
      handler(value) {
        if (!value) {
          this.exportOptions.selectedIssueForms = []
        }
      }
    }
  }
}