/**
 * @file It contains vuex exports module getter methods to access store variables.
 */
import { compareDates } from "@/utils"
import { ARRAY, DATA_EXPORT_TYPE } from "@/constants"

export default {
  exports            : state => state.exports,
  latestAllDataExport: state => {
    return state.exports.filter(exportItem =>
      exportItem.type === DATA_EXPORT_TYPE.ALL
    ).sort((export1, export2) =>
      compareDates(export2.createdAt, export1.createdAt)
    )[ARRAY.FIRST]
  },
  latestAnalyticsExport: state => {
    return state.exports.filter(exportItem =>
      exportItem.type === DATA_EXPORT_TYPE.ANALYTICS
    ).sort((export1, export2) =>
      compareDates(export2.createdAt, export1.createdAt)
    )[ARRAY.FIRST]
  },
  latestIssuesExport: state => {
    return state.exports.filter(exportItem =>
      [DATA_EXPORT_TYPE.ISSUES_WITH_SELECTED_FIELDS, DATA_EXPORT_TYPE.ISSUES_WITH_ALL_FIELDS].includes(exportItem.type)
    ).sort((export1, export2) =>
      compareDates(export2.createdAt, export1.createdAt)
    )[ARRAY.FIRST]
  },
  latestIssueDocxExport: state => {
    return state.exports.filter(exportItem =>
      exportItem.type === DATA_EXPORT_TYPE.ISSUE_DOCX
    ).sort((export1, export2) =>
      compareDates(export2.createdAt, export1.createdAt)
    )[ARRAY.FIRST]
  },
  latestIssuePdfExport: state => {
    return state.exports.filter(exportItem =>
      exportItem.type === DATA_EXPORT_TYPE.ISSUE_PDF
    ).sort((export1, export2) =>
      compareDates(export2.createdAt, export1.createdAt)
    )[ARRAY.FIRST]
  },
  isAddingExport  : state => state.addingExport,
  isUpdatingExport: state => state.updatingExport,
  isExportUpdated : state => state.exportUpdated
}