/**
 * @file It sets up routing for this application using vue-router.
 * It contains all the routes for this application.
 */
import Vue from "vue"
import Router from "vue-router"
import routeCheck from "@/middleware/route-check"
Vue.use(Router)

const router = new Router({
  mode  : "history",
  base  : process.env.BASE_URL,
  routes: [{
    path    : "*",
    redirect: "/dashboard"
  },
  {
    path    : "/analytics",
    redirect: "/analytics/views"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      layout   : "Default",
      protected: true
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/dashboard")
  },
  {
    path: "/not-found",
    name: "not-found",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/not-found")
  },
  {
    path: "/login",
    name: "login",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/login")
  },
  {
    path: "/test-sso",
    name: "test-sso",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "test-sso" */ "@/views/test-sso")
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/forgot-password")
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "reset-password" */ "@/views/reset-password")
  },
  {
    path: "/configurations",
    name: "configurations",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "configurations" */ "@/views/configurations")
  },
  {
    path: "/users",
    name: "users",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "users" */ "@/views/users")
  },
  {
    path: "/users/add",
    name: "user-add",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "user-add" */ "@/views/user-add")
  },
  {
    path: "/users/:id",
    name: "user",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "user" */ "@/views/user")
  },
  {
    path: "/groups",
    name: "groups",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "groups" */ "@/views/groups")
  },
  {
    path: "/groups/:id",
    name: "group",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "group" */ "@/views/group")
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "logout" */ "@/views/logout")
  },
  {
    path: "/domains",
    name: "domains",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "domains" */ "@/views/domains")
  },
  {
    path: "/domains/:id",
    name: "domain",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "domain" */ "@/views/domain")
  },
  {
    path: "/channels",
    name: "channels",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "channels" */ "@/views/channels")
  },
  {
    path: "/channels/:id",
    name: "channel",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "channel" */ "@/views/channel"),
      sidePanel: () => import(/* webpackChunkName: "link-display-name-side-panel" */ "@/views/link-display-name-side-panel")
    }
  },
  {
    path: "/domains/:domainId/channels/:id",
    name: "domain-channel",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "channel" */ "@/views/channel")
  },
  {
    path: "/analytics/views",
    name: "analytics-views",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "analytics-views" */ "@/views/analytics")
  },
  {
    path: "/analytics/views/:id",
    name: "analytics-view",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "analytics-view" */ "@/views/analytics")
  },
  {
    path: "/analytics/export",
    name: "analytics-export",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "analytics-export" */ "@/views/analytics")
  },
  {
    path: "/roles",
    name: "roles",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "roles" */ "@/views/roles")
  },
  {
    path: "/roles/:id",
    name: "role",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "role" */ "@/views/role")
  },
  {
    path: "/issues",
    name: "issues",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "issues" */ "@/views/issues")
  },
  {
    path: "/filters/:filterId",
    name: "filter",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "issues" */ "@/views/issues")
  },
  {
    path: "/issues/:id",
    name: "issue",
    meta: {
      layout            : "Default",
      protected         : true,
      roleTypes         : ["issue"],
      sidePanel         : true,
      sidePanelWidth    : 426,
      sidePanelMini     : true,
      sidePanelPermanent: true
    },
    components: {
      default  : () => import(/* webpackChunkName: "issue" */ "@/views/issue"),
      sidePanel: () => import(/* webpackChunkName: "issue-side-panel" */ "@/views/issue-side-panel")
    }
  },
  {
    path: "/issues/:id/print",
    name: "issue-print",
    meta: {
      layout   : "Blank",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "issue-print" */ "@/views/issue-print")
  },

  {
    path: "/issues/:id/accesses",
    name: "issue-accesses",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "issue-accesses" */ "@/views/issue-accesses")
  },
  {
    path: "/issues/:issueId/:id",
    name: "task",
    meta: {
      layout            : "Default",
      protected         : true,
      roleTypes         : ["issue"],
      sidePanel         : true,
      sidePanelWidth    : 426,
      sidePanelMini     : true,
      sidePanelPermanent: true
    },
    components: {
      default  : () => import(/* webpackChunkName: "issue" */ "@/views/issue"),
      sidePanel: () => import(/* webpackChunkName: "issue-side-panel" */ "@/views/issue-side-panel")
    }
  },
  {
    path: "/exceptions",
    name: "exceptions",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "exceptions" */ "@/views/exceptions")
  },
  {
    path: "/logs",
    name: "logs",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "logs" */ "@/views/logs")
  },
  {
    path: "/support",
    name: "support",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "support" */ "@/views/support")
  },
  {
    path: "/user-settings",
    name: "user-settings",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global", "issue"]
    },
    component: () => import(/* webpackChunkName: "user-settings" */ "@/views/user-settings")
  },
  {
    path: "/export",
    name: "export",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "export" */ "@/views/export")
  },
  {
    path: "/option-lists",
    name: "option-lists",
    meta: {
      layout   : "Default",
      title    : "730",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "export" */ "@/views/option-lists")
  },
  {
    path: "/option-lists/:id",
    name: "option-list",
    meta: {
      layout            : "Default",
      protected         : true,
      roleTypes         : ["global"],
      sidePanel         : true,
      sidePanelWidth    : 354,
      sidePanelPermanent: true,
      sidePanelColor    : "grey lighten-5"
    },
    components: {
      default  : () => import(/* webpackChunkName: "option-list" */ "@/views/option-list"),
      sidePanel: () => import(/* webpackChunkName: "option-list-side-panel" */ "@/views/option-list-side-panel")
    }
  },
  {
    path: "/reporter-intake-forms",
    name: "reporter-intake-forms",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "reporter-intake-forms" */ "@/views/reporter-intake-forms")
  },
  {
    path: "/fields",
    name: "fields",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "fields" */ "@/views/fields")
  },
  {
    path: "/fields/:id",
    name: "field",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "fields" */ "@/views/fields"),
      sidePanel: () => import(/* webpackChunkName: "field" */ "@/views/field")
    }
  },
  {
    path: "/reporter-intake-forms/:id",
    name: "reporter-intake-form",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "reporter-intake-form" */ "@/views/reporter-intake-form")
  },
  {
    path: "/reporter-intake-forms/:id/configurations/:configurationId",
    name: "reporter-intake-form-template-configuration",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "reporter-intake-form" */ "@/views/reporter-intake-form"),
      sidePanel: () => import(/* webpackChunkName: "reporter-intake-form-field" */ "@/views/reporter-intake-form-field")
    }
  },
  {
    path: "/issue-forms",
    name: "issue-forms",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "issue-forms" */ "@/views/issue-forms")
  },
  {
    path: "/issue-forms/:id",
    name: "issue-form",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "issue-form" */ "@/views/issue-form")
  },
  {
    path: "/issue-forms/:id/configurations/:configurationId",
    name: "issue-form-field-configuration",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "issue-form" */ "@/views/issue-form"),
      sidePanel: () => import(/* webpackChunkName: "issue-form-field" */ "@/views/issue-form-field")
    }
  },
  {
    path: "/issue-fields",
    name: "issue-fields",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "issue-fields-and-forms" */ "@/views/issue-fields-and-forms")
  },
  {
    path: "/issue-fields/:id",
    name: "issue-field",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "issue-fields-and-forms" */ "@/views/issue-fields-and-forms"),
      sidePanel: () => import(/* webpackChunkName: "issue-field" */ "@/views/issue-field")
    }
  },
  {
    path: "/issue-form-templates",
    name: "issue-form-templates",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    components: {
      default: () => import(/* webpackChunkName: "issue-fields-and-forms" */ "@/views/issue-fields-and-forms")
    }
  },
  {
    path: "/issue-form-templates/:id",
    name: "issue-form-template",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "issue-fields-and-forms" */ "@/views/issue-fields-and-forms"),
      sidePanel: () => import(/* webpackChunkName: "issue-form-template" */ "@/views/issue-form-template")
    }
  },
  {
    path: "/automations",
    name: "automations",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "automations" */ "@/views/automations")
  },
  {
    path: "/automations/add",
    name: "automation-add",
    meta: {
      layout     : "Default",
      protected  : true,
      roleTypes  : ["global"],
      breadcrumbs: 1
    },
    component: () => import(/* webpackChunkName: "automation-add" */ "@/views/automation")
  },
  {
    path: "/automations/:id",
    name: "automation-edit",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "automation-edit" */ "@/views/automation")
  },
  {
    path: "/workflows/:id",
    name: "workflow",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "workflow" */ "@/views/workflow")
  },
  {
    path: "/workflows",
    name: "workflows",
    meta: {
      layout   : "Default",
      title    : "1419",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "workflows" */ "@/views/workflows")
  },
  {
    path: "/workflows/:id/statuses",
    name: "workflow-status-side-panel",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "workflow" */ "@/views/workflow"),
      sidePanel: () => import(/* webpackChunkName: "workflow-status-side-panel" */ "@/views/workflow-status-side-panel")
    }
  },
  {
    path: "/workflows/:id/transitions",
    name: "transition",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "transition" */ "@/views/transition")
  },
  {
    path: "/screens",
    name: "screens",
    meta: {
      layout   : "Default",
      title    : "1389",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "screens" */ "@/views/screens")
  },
  {
    path: "/screens/:id",
    name: "screen",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "screen" */ "@/views/screen")
  },
  {
    path: "/screens/:id/items/:itemId",
    name: "screen-item-side-panel",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "screen" */ "@/views/screen"),
      sidePanel: () => import(/* webpackChunkName: "screen-item-side-panel" */ "@/views/screen-side-panel")
    }
  },
  {
    path: "/screens/:id/items/:formId/:itemId",
    name: "screen-item-side-panel-with-form",
    meta: {
      layout        : "Default",
      protected     : true,
      roleTypes     : ["global"],
      sidePanel     : true,
      sidePanelWidth: 354
    },
    components: {
      default  : () => import(/* webpackChunkName: "screen" */ "@/views/screen"),
      sidePanel: () => import(/* webpackChunkName: "screen-item-side-panel" */ "@/views/screen-side-panel")
    }
  },
  {
    path: "/reply-templates",
    name: "reply-templates",
    meta: {
      layout   : "Default",
      title    : "1714",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "reply-templates" */ "@/views/reply-templates")
  },
  {
    path: "/reply-templates/:id",
    name: "reply-template",
    meta: {
      layout   : "Default",
      protected: true,
      roleTypes: ["global"]
    },
    component: () => import(/* webpackChunkName: "reply-template" */ "@/views/reply-template")
  },
  {
    path: "/forbidden",
    name: "forbidden",
    meta: {
      layout: "Blank"
    },
    component: () => import(/* webpackChunkName: "forbidden" */ "@/views/forbidden")
  },
  {
    path: "/filters",
    name: "filters",
    meta: {
      layout   : "Default",
      title    : "2065",
      protected: true,
      roleTypes: ["issue"]
    },
    component: () => import(/* webpackChunkName: "filters" */ "@/views/filters")
  }]
})

router.beforeEach(routeCheck)
export default router