var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"chart_form",attrs:{"data-cy":"6638"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"6639"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormSubmit)}}},[_c('v-card',{staticClass:"pa-2",attrs:{"data-cy":"6640","height":"817"}},[(!_vm.pIsEditMode)?_c('v-card-title',{ref:"add_chart_form_title",staticClass:"text-h5 grey--text text--darken-4",attrs:{"data-cy":"6641"}},[_vm._v(_vm._s(_vm.$t("2256")))]):_c('v-card-title',{ref:"edit_chart_form_title",staticClass:"text-h5 grey--text text--darken-4",attrs:{"data-cy":"7055"}},[_vm._v(_vm._s(_vm.$t("2471")))]),_c('v-divider',{staticClass:"grey lighten-4",attrs:{"data-cy":"6642"}}),_c('div',{staticClass:"d-block px-4 pb-0 card-content-max-height",attrs:{"data-cy":"6643"}},[_c('v-card-subtitle',{ref:"chart_form_subtitle",staticClass:"pa-0 mt-4 black--text body-1",attrs:{"data-cy":"6644"}},[_vm._v(_vm._s(_vm.$t("2257"))),_c('a',{ref:"create_charts_help_center_link",staticClass:"link secondary--text",staticStyle:{"text-decoration":"none"},attrs:{"data-cy":"6645","href":_vm.creatingChartsHelpCenterUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("2263")))])]),_c('v-card-text',{staticClass:"pa-0 mt-4",attrs:{"data-cy":"6646"}},[_c('div',{ref:"chart_form_chart_details",staticClass:"d-block black--text body-1 mb-3",attrs:{"data-cy":"6647"}},[_vm._v(_vm._s(_vm.$t("2258")))]),_c('themis-input',{ref:"themis_input_chart_name",attrs:{"data-cy":"6692","p-rules":("required|duplicate:" + _vm.isChartNameDuplicate + "|max:" + _vm.chartNameCharacterLimit),"p-label":_vm._f("required")(("" + (_vm.$t('2269')))),"p-name":"2269","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"text_field_chart_name",attrs:{"data-cy":"6693","color":"secondary","placeholder":_vm.$t('2270'),"error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.chart.name),callback:function ($$v) {_vm.$set(_vm.chart, "name", $$v)},expression:"chart.name"}})]}}],null,true)}),_c('v-divider',{staticClass:"mb-6",attrs:{"data-cy":"6648"}}),_c('div',{ref:"chart_form_filters",staticClass:"d-block black--text body-1 my-4",attrs:{"data-cy":"6649"}},[_vm._v(_vm._s(_vm.$t("2259")))]),_c('themis-input',{ref:"themis_input_filters",attrs:{"data-cy":"6694","p-label":_vm.$t('2271'),"p-name":"2271","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-autocomplete',{ref:"filters_autocomplete",attrs:{"data-cy":"6695","color":"secondary","background-color":"white","placeholder":_vm.$t('2272'),"items":_vm.filters,"item-text":"name","item-value":"id","clear-icon":"mdi-close-circle-outline","outlined":"","hide-details":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.chart.savedFilterId),callback:function ($$v) {_vm.$set(_vm.chart, "savedFilterId", $$v)},expression:"chart.savedFilterId"}})]}}],null,true)}),_c('v-divider',{staticClass:"my-6",attrs:{"data-cy":"6650"}}),_c('div',{ref:"chart_form_chart_view",staticClass:"d-block black--text body-1 my-4",attrs:{"data-cy":"6651"}},[_vm._v(_vm._s(_vm.$t("2260")))]),_c('themis-input',{ref:"themis_input_chart_type",attrs:{"data-cy":"6704","p-label":_vm._f("required")(("" + (_vm.$t('2286')))),"p-rules":"required","p-name":"2286","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"chart_type_autocomplete",attrs:{"data-cy":"6705","color":"secondary","background-color":"white","placeholder":_vm.$t('2287'),"items":_vm.chartTypes,"item-text":"text","item-value":"value","clear-icon":"mdi-close-circle-outline","error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-icon',{staticClass:"mr-2",attrs:{"data-cy":"6705"}},[_c('v-icon',{staticClass:"mr-2",class:data.item.class || '',attrs:{"data-cy":"6706","color":"grey"}},[_vm._v(_vm._s(data.item.icon))])],1),_c('v-list-item-content',{attrs:{"data-cy":"6707"}},[_c('v-list-item-title',{attrs:{"data-cy":"6708"}},[_vm._v(_vm._s(data.item.text))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-icon',{staticClass:"mr-2",class:("" + (item.class || '')),attrs:{"data-cy":"6709","color":"grey"}},[_vm._v(_vm._s(item.icon))]),_c('span',{attrs:{"data-cy":"6710"}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(_vm.chart.type),callback:function ($$v) {_vm.$set(_vm.chart, "type", $$v)},expression:"chart.type"}})]}}],null,true)}),_c('themis-input',{ref:"themis_input_dimension",attrs:{"data-cy":"6721","p-label":_vm._f("required")(_vm.$t('2301')),"p-rules":"required","p-name":"2301","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"append-label",fn:function(){return [_c('v-tooltip',{ref:"dimenision_tooltip",attrs:{"data-cy":"6737","max-width":"317","color":"grey darken-2","nudge-right":"160","nudge-top":"3","bottom":"","right":"","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"information_icon_dimension",staticClass:"ml-1 mb-1",attrs:{"data-cy":"6738","color":"grey","size":"20"}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('div',{staticClass:"d-block py-1",attrs:{"data-cy":"6739"}},[_vm._v(_vm._s(_vm.$t('2333')))])])]},proxy:true},{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"input_dimension",attrs:{"data-cy":"6722","color":"secondary","placeholder":_vm.$t('2302'),"items":_vm.dimensionItems,"item-text":"name","item-value":"value","clear-icon":"mdi-close-circle-outline","error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur,"input":_vm.handleDimensionInput},model:{value:(_vm.chart.dimension),callback:function ($$v) {_vm.$set(_vm.chart, "dimension", $$v)},expression:"chart.dimension"}})]}}],null,true)}),(_vm.showTimeframeInput)?_c('themis-input',{ref:"themis_input_timeframe",attrs:{"data-cy":"6956","p-label":_vm._f("required")(("" + (_vm.$t('2440')))),"p-rules":"required","p-name":"2440","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"timeframe_autocomplete",attrs:{"data-cy":"6957","color":"secondary","background-color":"white","placeholder":_vm.$t('2441'),"items":_vm.timeframes,"item-text":"text","item-value":"value","clear-icon":"mdi-close-circle-outline","error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"data-cy":"6958"}},[_c('v-list-item-title',{attrs:{"data-cy":"6959"}},[_vm._v(_vm._s(data.item.text))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{attrs:{"data-cy":"6960"}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(_vm.timeframe),callback:function ($$v) {_vm.timeframe=$$v},expression:"timeframe"}})]}}],null,true)}):_vm._e(),_c('themis-input',{ref:"themis_input_metric",attrs:{"data-cy":"6711","p-label":_vm._f("required")(("" + (_vm.$t('2288')))),"p-rules":"required","p-name":"2288","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"metric_autocomplete",attrs:{"data-cy":"6712","color":"secondary","background-color":"white","placeholder":_vm.$t('2290'),"items":_vm.metrics,"item-text":"text","item-value":"value","clear-icon":"mdi-close-circle-outline","error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"data-cy":"6713"}},[_c('v-list-item-title',{attrs:{"data-cy":"6714"}},[_vm._v(_vm._s(data.item.text))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{attrs:{"data-cy":"6715"}},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(_vm.chart.metric),callback:function ($$v) {_vm.$set(_vm.chart, "metric", $$v)},expression:"chart.metric"}})]}}],null,true)}),(_vm.showSubdivision)?_c('themis-input',{ref:"themis_input_subdivision",attrs:{"data-cy":"6723","p-label":_vm.$t('2304'),"p-name":"2304","p-label-class":"secondary--text"},scopedSlots:_vm._u([{key:"append-label",fn:function(){return [_c('v-tooltip',{ref:"subdivision_tooltip",attrs:{"data-cy":"6740","max-width":"330","color":"grey darken-2","nudge-right":"155","nudge-bottom":"9","top":"","right":"","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"information_icon_subdivision",staticClass:"ml-1 mb-1",attrs:{"data-cy":"6741","color":"grey","size":"20"}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('div',{staticClass:"d-block py-1",attrs:{"data-cy":"6742"}},[_vm._v(_vm._s(_vm.$t('2334')))])])]},proxy:true},{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
return [_c('v-autocomplete',{ref:"input_subdivision",attrs:{"data-cy":"6724","color":"secondary","placeholder":_vm.$t('2303'),"items":_vm.subdivisionItems,"item-text":"name","item-value":"value","clear-icon":"mdi-close-circle-outline","outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.chart.subdivision),callback:function ($$v) {_vm.$set(_vm.chart, "subdivision", $$v)},expression:"chart.subdivision"}})]}}],null,true)}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"mb-2",attrs:{"data-cy":"6652"}},[_c('v-row',{attrs:{"data-cy":"6653"}},[_c('v-col',{staticClass:"col-12 px-0 pt-1",attrs:{"data-cy":"6654"}},[_c('v-divider',{attrs:{"data-cy":"6655"}})],1),_c('v-col',{staticClass:"py-0 mx-2 text-right",attrs:{"data-cy":"6656"}},[_c('v-btn',{ref:"cancel_button",staticClass:"mr-6",attrs:{"data-cy":"6657","disabled":_vm.pIsAddingChart || _vm.pIsUpdatingChart,"text":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t("2261")))]),(!_vm.pIsEditMode)?_c('v-btn',{ref:"add_chart_button",attrs:{"data-cy":"6658","color":"primary","type":"submit","loading":_vm.pIsAddingChart}},[_vm._v(_vm._s(_vm.$t("2262")))]):_c('v-btn',{ref:"save_chart_button",attrs:{"data-cy":"7056","color":"primary","type":"submit","loading":_vm.pIsUpdatingChart,"disabled":!_vm.isChartChanged}},[_vm._v(_vm._s(_vm.$t("2472")))])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }