/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import {
  HTTP_STATUS_CODE,
  FILE_NAME,
  DATA_EXPORT_TYPE,
  ISSUES_DATA_EXPORT_POLL_INTERVAL,
  ISSUE_EXPORT_POLL_INTERVAL,
  DATA_EXPORT_STATUS
} from "@/constants"
import { downloadFile, generateMD5ForFile } from "@/utils"
import { getBaseURL } from "@/utils"

const path = "/v1/exports"

export default {

  /**
   * This action will load exports.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load exports.
   */
  async loadExports(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getExportsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getExportsResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("setExports", getExportsResponse.data)
    } else {
      // something went wrong while getting Exports
    }
  },

  /**
   * This action will load a particular export.
   * @param {*} context it is the store.
   * @param {*} payload it is id of the export to be loaded
   */
  async loadExport(context, payload) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getExportResponse = await httpClient.get(`/${payload}`)

    if (getExportResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateExports", [getExportResponse.data])
    } else {
      // something went wrong while getting an export
    }
  },

  /**
   * This action will create an export.
   * @param {*} context is the store
   * @param {*} payload contains exports details
   */
  async addExport(context, payload) {

    const { fileName, type, data } = payload
    let actualPayload
    let file
    let tempMd5

    if ([DATA_EXPORT_TYPE.ISSUE_DOCX, DATA_EXPORT_TYPE.ISSUE_PDF].includes(type)) {
      const fileData = JSON.stringify(data)
      file           = new File([fileData], "issue-export-data.json")
      tempMd5        = await generateMD5ForFile(file)
      actualPayload  = {
        type,
        md5: tempMd5
      }
    } else {
      actualPayload = {
        type
      }
    }
    context.commit("setAddingExport", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postExportResponse = await httpClient.post(undefined, actualPayload)

    if (postExportResponse.status === HTTP_STATUS_CODE.OK) {
      if ([DATA_EXPORT_TYPE.ISSUE_DOCX, DATA_EXPORT_TYPE.ISSUE_PDF].includes(postExportResponse.data.type)) {
        const { url, fields, ...otherResponseData } = postExportResponse.data

        const form = new FormData()
        Object.keys(fields).forEach(key => form.append(key, fields[key]))
        form.append("file", file)

        const headers = {
          "Content-MD5": Buffer.from(tempMd5, "hex").toString("base64")
        }

        const uploadFileToS3Response = await fetch(url, {
          headers,
          method: "POST",
          body  : form
        })

        if (uploadFileToS3Response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
          context.commit("updateExports", [otherResponseData])
          const payloadForUpdate = {
            ...otherResponseData,
            fileName,
            status: DATA_EXPORT_STATUS.INITIATED
          }
          context.dispatch("updateExport", payloadForUpdate)
        }
      } else {
        context.commit("updateExports", [postExportResponse.data])
        if ([
          DATA_EXPORT_TYPE.ISSUES_WITH_ALL_FIELDS,
          DATA_EXPORT_TYPE.ISSUES_WITH_SELECTED_FIELDS
        ].includes(postExportResponse.data.type)) {
          pollingForIssuesExport(context, { exportData: postExportResponse, fileName })
        }
      }
    } else if (postExportResponse.status === HTTP_STATUS_CODE.BAD_REQUEST) {
      await context.dispatch("loadExports")
    } else {
      // something went wrong while creating an export
    }
    context.commit("setAddingExport", false)
  },

  /**
   * This action downloads the latest data export
   * @param {*} context is the store.
   */
  async downloadDataExport(context, payload) {
    const latestExport           = payload.latestExport
    const fileNameFromPayload    = payload.fileName
    const httpClient             = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const getDownloadURLResponse = await httpClient.get(`/${latestExport.id}/download-url`)
    if (getDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      let fileName
      switch(latestExport.type) {
        case DATA_EXPORT_TYPE.ALL: {
          fileName = FILE_NAME.ALL_DATA_EXPORT_ZIP
          break
        }
        case DATA_EXPORT_TYPE.ANALYTICS: {
          fileName = FILE_NAME.GET_ANALYTICS_EXPORT(latestExport.createdAt.slice(0, 10))
          break
        }
        case DATA_EXPORT_TYPE.ISSUES_WITH_ALL_FIELDS:
        case DATA_EXPORT_TYPE.ISSUES_WITH_SELECTED_FIELDS: {
          fileName = FILE_NAME.ISSUES_EXPORT
          break
        }
        case DATA_EXPORT_TYPE.ISSUE_DOCX: {
          fileName = fileNameFromPayload
          break
        }
      }
      await downloadFile(getDownloadURLResponse.data, latestExport.md5, fileName)
    }
  },

  /**
   * This action downloads the latest data export
   * @param {*} context is the store.
   * @param {*} payload contains export details
   */
  async updateExport(context, payload) {
    const { status, id, fileName, ...otherPayloadItems } = payload
    context.commit("setUpdatingExport", true)
    context.commit("setExportUpdated", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateExportResponse = await httpClient.put(`/${id}`, { status })

    if (updateExportResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setExportUpdated", true)
      if ([DATA_EXPORT_TYPE.ISSUE_DOCX, DATA_EXPORT_TYPE.ISSUE_PDF].includes(otherPayloadItems.type)) {
        const exportData = {
          id,
          status,
          ...otherPayloadItems
        }
        context.commit("updateExports", [exportData])
        pollingForIssueDocxOrPdfExport(context, { exportData, fileName })
      } else {
        context.commit("updateExports", [payload])
      }
    }
    context.commit("setUpdatingExport", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setExports", [])
    context.commit("setAddingExport", false)
    context.commit("setUpdatingExport", false)
    context.commit("setExportUpdated", false)
  }
}

const pollingForIssuesExport = (context, data) => {
  const { exportData }      = data
  const exportStatusPolling = setInterval(async () => {
    const isLoggedIn = context.rootGetters["auth/isLoggedIn"]
    if (isLoggedIn) {
      await context.dispatch("loadExport", exportData.data.id)
      const latestExport = context.getters.latestIssuesExport
      if (latestExport.status === DATA_EXPORT_STATUS.COMPLETED) {
        context.dispatch("downloadDataExport", { latestExport })
        clearInterval(exportStatusPolling)
      } else if (latestExport.status === DATA_EXPORT_STATUS.FAILED) {
        clearInterval(exportStatusPolling)
      }
    } else {
      clearInterval(exportStatusPolling)
    }
  },
  ISSUES_DATA_EXPORT_POLL_INTERVAL)
}

const pollingForIssueDocxOrPdfExport = (context, data) => {
  const { exportData, fileName } = data
  const exportStatusPolling      = setInterval(async () => {
    const isLoggedIn = context.rootGetters["auth/isLoggedIn"]
    if (isLoggedIn) {
      let latestExport
      await context.dispatch("loadExport", exportData.id)
      if (exportData.type === DATA_EXPORT_TYPE.ISSUE_DOCX) {
        latestExport = context.getters.latestIssueDocxExport
      } else {
        latestExport = context.getters.latestIssuePdfExport
      }
      if (latestExport.status === DATA_EXPORT_STATUS.COMPLETED) {
        context.dispatch("downloadDataExport", { latestExport, fileName })
        clearInterval(exportStatusPolling)
      } else if (latestExport.status === DATA_EXPORT_STATUS.FAILED) {
        clearInterval(exportStatusPolling)
      }
    } else {
      clearInterval(exportStatusPolling)
    }
  },
  ISSUE_EXPORT_POLL_INTERVAL)
}