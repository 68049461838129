var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table_csv_data_validation",attrs:{"data-cy":"4720","item-key":"id","items":_vm.itemsForValidationTable,"headers":_vm.headersForCsvDataValidationTable,"single-select":"","fixed-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.speakupFields",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('themis-input',{ref:"themis_input_speak_up_fields",attrs:{"data-cy":"4726","p-rules":_vm.getValidationRules(item)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:("autocomplete_speak_up_fields" + index),attrs:{"data-cy":"4723","color":"secondary","items":item.speakUpFieldsItems,"item-text":"name","item-value":"value","error-messages":errors,"hide-details":!errors.length,"outlined":""},on:{"focus":onFocus,"click":onFocus,"change":function($event){return _vm.handleSelectionChange(item)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('themis-validation-status-icons',{attrs:{"data-cy":"4728","p-skip":item.defaultStateOfOuterIcon,"p-success":item.validationSuccess,"p-failure":item.validationFailure,"p-in-progress":item.validationInProgress}})]},proxy:true}],null,true),model:{value:(item.speakupFields),callback:function ($$v) {_vm.$set(item, "speakupFields", $$v)},expression:"item.speakupFields"}})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }